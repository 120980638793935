import React,{useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Navbar from '../admin-components/Navbar'
import Sidebar from '../admin-components/SideBar'
import {  Link } from "react-router-dom";
import { medicineList } from '../redux/actions/medicineActions';
import { labTestList } from '../redux/actions/labTestActions'
import { ayurvedaList } from '../redux/actions/ayurvedaActions'
import { companyList } from '../redux/actions/companyActions';
import { cartList } from '../redux/actions/cartActions';
import { orderList } from '../redux/actions/orderActions';

import { Chart } from "react-google-charts";
import axios from 'axios';
import { API_ENDPOINT } from '../rd-utils/constants';
import { getHeader } from '../rd-utils/util';
import { ApiSharp } from '@mui/icons-material';
const Dashboard = () => {
   const [approvedOrder, setApprovedOrder] = useState(0)
   const [cancelledOrder, setCancelledOrder] = useState(0)
   const [pendingOrder, setPendingOrder] = useState(0)


   const [t, setT] = useState({numCom: '...', numMed: '...', numLab: '...', numAyu: '...'})


    useEffect(()=>{
            getTotals()
            graphInfo()
    }, [])
   function getTotals(){
        axios.get(API_ENDPOINT+'admin/get-totals', getHeader()).then(res=>{
            let d = res.data
            if(d.success){
                setT(d.message)
            }
        })
   }
   function graphInfo(){
        axios.get(API_ENDPOINT+'admin/orders-info', getHeader()).then(res=>{
            let d = res.data
            if(d.success){
                let rA = d.message
                let aO = rA.find(o=>o._id === 'approved')
                let cO = rA.find(o=>o._id === 'cancelled')
                let pO = rA.find(o=>o._id === 'pending')
                let aNumO = aO === undefined ?0:aO.count
                let cNumO = cO === undefined ?0:cO.count
                let pNumO = pO === undefined ?0:pO.count
                setPendingOrder(pNumO)
                setApprovedOrder(aNumO)
                setCancelledOrder(cNumO)
            }
        })
   }
    
    
    // orderData && orderData.map(filteredDisease => (
    //     console.log(filteredDisease)
    //   ))

    // Bar chart
     const data = [
        ["Products", "Count"],
        ["Medicine", t.numMed],
        ["Ayurveda", t.numAyu],
        ["Lab Test", t.numLab]
      ];
       const options = {
        title: "Products Analytics",
        is3D: true,
      };
      
    //   Pie Chart
    const data1 = [
        ["Order", "Status"],
        ["Approved", approvedOrder],
        ["Pending", pendingOrder],
        ["Cancelled", cancelledOrder]
      ];
      
       const options1 = {
        title: "Orders Analytics",
        is3D: true,
      };

 



    // line chart

     const line_data = [
        [
          "Day",
          "Medicine",
          "Ayurveda",
          "Lab Test",
        ],
        [1, 37.8, 80.8, 41.8],
        [2, 30.9, 69.5, 32.4],
        [3, 25.4, 57, 25.7],    
        [4, 11.7, 18.8, 10.5],
        [5, 11.9, 17.6, 10.4],
        [6, 8.8, 13.6, 7.7],
        [7, 7.6, 12.3, 9.6],
        [8, 12.3, 29.2, 10.6],
        [9, 16.9, 42.9, 14.8],
        [10, 12.8, 30.9, 11.6],
        [11, 5.3, 7.9, 4.7],
        [12, 6.6, 8.4, 5.2],
        [13, 4.8, 6.3, 3.6],
        [14, 4.2, 6.2, 3.4],
      ];
      
       const line_options = {
        chart: {
          title: "Comprising of Medicines",
          subtitle: "in millions of dollars (USD)",
        },
      };
      
    return (
    <div className='container'>
        <div className="row g-6 mb-6 my-5">
        <div className="col-xl-3 col-sm-6 col-12">
            <div className="card shadow border-0">
            <div className="card-body">
                <div className="row">
                <div className="col">
                    <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                    Companies
                    </span>
                    <span className="h3 font-bold mb-0">{t.numCom}</span>
                </div>
                <div className="col-auto">
                    <i className="fa-solid fa-hospital fa-2xl text-info"></i>
                    <div className="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                    {/* <i className="bi bi-credit-card" /> */}
                    </div>
                </div>
                </div>
                {/* <div className="mt-2 mb-0 text-sm">
                <span className="badge badge-pill bg-soft-success text-success me-2">
                    <i className="bi bi-arrow-up me-1" />
                    13%
                </span>
                <span className="text-nowrap text-xs text-muted">
                    Since last month
                </span>
                </div> */}
            </div>
            </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
            <div className="card shadow border-0">
            <div className="card-body">
                <div className="row">
                <div className="col">
                    <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                    Medicine
                    </span>
                    <span className="h3 font-bold mb-0">{t.numMed}</span>
                </div>
                <div className="col-auto">
                <i className="fa-solid fa-pills fa-2xl text-info"></i>
                    {/* <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                    <i className="bi bi-people" />
                    </div> */}
                </div>
                </div>
                {/* <div className="mt-2 mb-0 text-sm">
                <span className="badge badge-pill bg-soft-success text-success me-2">
                    <i className="bi bi-arrow-up me-1" />
                    30%
                </span>
                <span className="text-nowrap text-xs text-muted">
                    Since last month
                </span>
                </div> */}
            </div>
            </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
            <div className="card shadow border-0">
            <div className="card-body">
                <div className="row">
                <div className="col">
                    <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                    Lab Test
                    </span>
                    <span className="h3 font-bold mb-0">{t.numLab}</span>
                </div>
                <div className="col-auto">
                <i className="fa-solid fa-stethoscope fa-2xl text-info"></i>
                </div>
                </div>
                {/* <div className="mt-2 mb-0 text-sm">
                <span className="badge badge-pill bg-soft-danger text-danger me-2">
                    <i className="bi bi-arrow-down me-1" />
                    -5%
                </span>
                <span className="text-nowrap text-xs text-muted">
                    Since last month
                </span>
                </div> */}
            </div>
            </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
            <div className="card shadow border-0">
            <div className="card-body">
                <div className="row">
                <div className="col">
                    <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                    Ayurveda
                    </span>
                    <span className="h3 font-bold mb-0">{t.numAyu}</span>
                </div>
                <div className="col-auto">
                <i className="fa-solid fa-tablets fa-2xl text-info"></i>
                </div>
                </div>
                {/* <div className="mt-2 mb-0 text-sm">
                <span className="badge badge-pill bg-soft-success text-success me-2">
                    <i className="bi bi-arrow-up me-1" />
                    10%
                </span>
                <span className="text-nowrap text-xs text-muted">
                    Since last month
                </span>
                </div> */}
            </div>
            </div>
        </div>
        </div>

        <div className="row my-5">
            <div className="col-md-6 my-3">
                <div className="card">
                    <div className="card-body">
                    <Chart
                    chartType="PieChart"
                    data={data}
                    options={options}
                    width={"100%"}
                    height={"400px"}/>
                    </div>
                </div>
            </div>


            <div className="col-md-6 my-3 ">
                <div className="card">

               <div className="card-body">
                    <Chart
                    chartType="PieChart"
                    data={data1}
                    options={options1}
                    width={"100%"}
                    height={"400px"}
                    />
                    </div>
                    </div>
            </div>
           
            {/* <div className="col-md-6 my-3">
                <div className="card">
                    <div className="card-body">
                    <Chart
                        chartType="Line"
                        width="100%"
                        height="400px"
                        data={line_data}
                        options={line_options}
                        />
                    </div>
                </div>
            </div> */}

        </div>
  </div>
  )
}

export default Dashboard