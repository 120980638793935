import React from 'react'
import b1 from '../../assets/images/blog/b1.jpg'

export default function BlogCard(props) {

  return (
    <>
    <div className='col-md-4'>
        <div className="card effect my-3" >
            <img src={b1} width={100} className="card-img-top" alt="..." />
            <div className="card-body">
                <h4 className="m-0 fw-bold">{props.name.substring(0, 15)}...</h4>
                <p className="card-text"> Testosterone is a vital hormo</p>
                {/* <a href="#" className="btn btn-outline-dark btn-sm">View</a> */}
            </div>
        </div>
    </div>

    </>
  )
}
