import React, {lazy, Suspense, useEffect} from 'react'
import {Route, Routes, Outlet} from 'react-router-dom'
import Sidebar from '../admin-components/SideBar';
import Navbar from '../admin-components/Navbar';
import {useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Dashboard from '../admin-pages/Dashboard';
import UserList from '../admin-pages/UserList';
import AddCompany from '../admin-pages/AddCompany';
import AddMedicine from '../admin-pages/AddMedicine';
import CompanyList from '../admin-pages/CompanyList';

import '../assets/css/adminStyle.css'
// import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// const Dashboard = lazy(() => import("../admin-pages/Dashboard"));

const Admin = () => {
  return (
    <>
        <div className='admin-pages-c row'>
            <div className="col-2 p-0">
                <Sidebar />
            </div>
            <div className="col-10 p-0">
                <Navbar />
                <div style={{overflowY: 'auto', height: '90vh'}}>
                <Outlet />
                </div>
            </div>
        </div>
    </>
  )
}

export default Admin