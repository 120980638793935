import axios from 'axios'
import React,  {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import CustomCircularP from '../rd-pages/widgets/customCircularP'
import { API_ENDPOINT } from '../rd-utils/constants'
import { getHeader } from '../rd-utils/util'
import { addCompany} from '../redux/actions/companyActions'

const AddDisease = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [disease, setDisease] = useState('')

    const addDisease = (e) => {
        e.preventDefault()
        setLoading(true)
        let params = {name: disease}
        axios.post(API_ENDPOINT+'admin/add-disease', params, getHeader()).then(res=>{
          setLoading(false)
          let d = res.data
          console.log(d)
          if(d.success){
            navigate('/diseases')
          }else{
            toast(d.message)
          }
        })
    }

  return (
    <div>
      <CustomCircularP show={loading}/>
        <div className='container p-5'>

            <h1>Add Disease</h1>
            <form className='row mt-3' onSubmit={(e) =>addDisease(e)}>
                <div className='col-6 mb-3'>
                <label htmlFor="companyName" className="form-label"> Disease Name</label>
                <input className="form-control" type="text" placeholder='Disease Name' value={disease}  onChange={(e)=>setDisease(e.target.value)} required/>
                </div>
                <div>
                    <button type='submit' className='btn btn-primary mt-3'>Add Disease</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default AddDisease