import React from 'react'
const ItemImage = (props)=>{
    const {images, alt} = props
    let img = images[0].url
    let imgO = images.find(im=>im.isDefault)
    if(imgO !== undefined){
        img = imgO.url
    }
    return(
        <img src={img} className="img-fluid"
        style={{height:"100px"}} alt={alt} />
    )
}
export default ItemImage