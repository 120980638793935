import { DoctorTypes } from '../constants/doctorConstant'
import axios from "axios";
import { toast } from 'react-toastify';
import {
  DOCTOR_ADD_REQUEST,
  DOCTOR_ADD_SUCCESS,
  DOCTOR_ADD_FAIL,
  DOCTOR_LIST_REQUEST,
  DOCTOR_LIST_SUCCESS,
  DOCTOR_LIST_FAIL,
  DOCTOR_DETAILS_REQUEST,
  DOCTOR_DETAILS_SUCCESS,
  DOCTOR_DETAILS_FAIL,
  DOCTOR_UPDATE_REQUEST,
  DOCTOR_UPDATE_SUCCESS,
  DOCTOR_UPDATE_FAIL,
  DOCTOR_DELETE_REQUEST,
  DOCTOR_DELETE_SUCCESS,
  DOCTOR_DELETE_FAIL,
} from '../constants/doctorConstants'
import { logout } from './userActions';

export const setDoctors = (doctors) =>{
    return {
        type: DoctorTypes.SET_DOCTORS,
        payload: doctors,
    }
}

export const selectedDoctor = (doctor) =>{
    return {
        type: DoctorTypes.SELECTED_DOCTOR,
        payload: doctor,
    }
}

export const addDoctor = (doctorData) => async(dispatch, getState) => {
    try {

        dispatch({
          type: DOCTOR_ADD_REQUEST
      })
        const {
            userSignIn: { userInfo },
          } = getState()
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth': userInfo.token
            },
          }
        const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}api/doctor/add`, doctorData, config)
        dispatch({
            type: DOCTOR_ADD_SUCCESS,
            payload: data
        })

        if(data.status === 409){
          toast.warn(data.message, {
            position: toast.POSITION.BOTTOM_LEFT
          });
        }else{

        toast.success("Doctor Added Successful", {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
        
    } catch (error) {
      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }
        dispatch({
            type: DOCTOR_ADD_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}

export const updateDoctor = (doctorData) => async(dispatch, getState) => {
    dispatch({
        type: DOCTOR_UPDATE_REQUEST
    })
    try {

        const {
            userSignIn: { userInfo },
          } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth': userInfo.token
            },
          }

        const {data} = await axios.put(`${process.env.REACT_APP_BASE_URL}api/doctor/${doctorData._id}`, doctorData, config)

        dispatch({
            type: DOCTOR_UPDATE_SUCCESS,
            payload: data
        })

        toast.success("Doctor Details Successfully Updated", {
          position: toast.POSITION.BOTTOM_LEFT
        });
        
    } catch (error) {
      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }

        dispatch({
            type: DOCTOR_UPDATE_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}

export const doctorList = () => async(dispatch, getState) => {
    dispatch({
        type: DOCTOR_LIST_REQUEST
    })
    try {
        const {
            userSignIn: { userInfo },
          } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth': userInfo.token
            },
          }

        const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}api/doctor/list`, config)
        
          
        dispatch({
            type: DOCTOR_LIST_SUCCESS,
            payload: data
        })

     
        
    } catch (error) {
        dispatch({
            type: DOCTOR_LIST_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
    }
}

export const doctorDetails = (id) => async(dispatch, getState) => {
    dispatch({
        type: DOCTOR_DETAILS_REQUEST
    })
    try {

        const {
            userSignIn: { userInfo },
          } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json',
              
            },
          }

        const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}api/doctor/${id}`, config)

        dispatch({
            type: DOCTOR_DETAILS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }

        dispatch({
            type: DOCTOR_DETAILS_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}

export const deleteDoctor = (id) => async(dispatch, getState) => {
    dispatch({
        type: DOCTOR_DELETE_REQUEST
    })
    try {
        const {
            userSignIn: { userInfo },
          } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth': userInfo.token
            },
          }

        const {data} = await axios.delete(`${process.env.REACT_APP_BASE_URL}api/doctor/${id}`, config)

        dispatch({
            type: DOCTOR_DELETE_SUCCESS,
            payload: data
        })
        
    } catch (error) {
      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }

        dispatch({
            type: DOCTOR_DELETE_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}
