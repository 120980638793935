import { LoadingButton } from '@mui/lab';
import React, { useRef, useState } from 'react';
import BackBtn from '../rd-pages/widgets/backBtn';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { Editor } from 'react-draft-wysiwyg';
import {convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { EditorState } from 'draft-js';

import { getHeader } from '../rd-utils/util';
import { toast } from 'react-toastify';
import { API_ENDPOINT, PLACEHOLDER_IMAGE } from '../rd-utils/constants';
import axios from 'axios';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CustomCircularP from '../rd-pages/widgets/customCircularP';
import { useEffect } from 'react';
const AddBlog = (props) => {
    const {back, onBlogAdded} = props
    const [selectedImage, setSelectedImage] = useState(PLACEHOLDER_IMAGE)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [category, setCategory] = useState('')
    const [bheader, setBheader] = useState('')
    const [shortIntro, setShortIntro] = useState('')
    const [desc, setDesc] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const preview_img = useRef()

    const [picLoading, setPicLoading] = useState(false)

    const onEditorStateChange = editorState => {
        setEditorState(editorState);
        setDesc(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }

    const onImgSelect = event=>{
        const [file] = preview_img.current.files
        if(file){
            let fd = new FormData()
            fd.append("image", file)
            setPicLoading(true)
            axios.post(API_ENDPOINT+'admin/upload-image-only', fd, getHeader()).then(res=>{
                let d = res.data
                setPicLoading(false)
                if(d.success){
                    let img_url = d.message
                    setSelectedImage(img_url)
                }else{
                    toast(d.message)
                }
            })
        
        }
      }
    const categoriesList = [
        {"category": "Ayurvedic & Home Remedies"},
        {"category": "Disease & Disorder"}, 
        {"category": "Eye care"}, 
        {"category": "Food & Nutrition"},
        {"category": "Hair care"},
        {"category": "Health Day"}, 
        {"category": "Health News"}, 
        {"category": "Mental Health"},  
        {"category": "Mind & Body"},
        {"category": "Personal Care"}  
    ]

    const chooseImage = ()=>{
        preview_img.current.click()
    }
    const uploadCallback = (file, callback) => {
        console.log("called")
        return new Promise((resolve, reject) => {
          const reader = new window.FileReader();
          reader.onloadend = async () => {
             const form_data = new FormData();
             form_data.append("image", file);  
             axios.post(API_ENDPOINT+'admin/upload-image-only', form_data, getHeader()).then(res=>{
                let d = res.data
                if(d.success){
                    resolve({ data: { link: d.message} });
                }else{
                    toast(d.message)
                }
             })

          };
          reader.readAsDataURL(file);
        });
    }
    const config = {
        image: { 
            uploadCallback: uploadCallback,
        },
    }
    const submitData = ()=>{
        if(selectedImage === PLACEHOLDER_IMAGE){
            toast("Please select preview image")
        }else if(category === ''){
            toast('Please select category')
        }else if(bheader === ''){
            toast('Please Enter Header')
        }else if(shortIntro === ''){
            toast('Please Enter Short Intro')
        }else if(desc.length <8){
            toast('Please Enter Blog')
        }else{
            let toUpdate = {
                image: selectedImage,
                category: category,
                header: bheader,
                shortIntro: shortIntro,
                desc: desc
            }
            setSubmitting(true)
            axios.post(API_ENDPOINT+'admin/add-blog', toUpdate, getHeader()).then(res=>{
                setSubmitting(false)
                let d = res.data
                if(d.success){
                    toast(d.message)
                    //onBlogAdded({...toUpdate, createdOn: new Date(), updatedOn: new Date()})
                    window.location.reload()
                }
            })

        }
    }
    return (
        <div>
             <BackBtn onClick={back}/>
             <input onChange={onImgSelect} ref={preview_img} type="file" accept="image/*" style={{display: 'none'}} />
             <div style={{marginTop: '12px', maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto'}}>
             <LoadingButton onClick={chooseImage} startIcon={<AddAPhotoIcon />} loading={picLoading} loadingPosition='start' variant='contained' >Choose Preview Image</LoadingButton>
             <img src={selectedImage} style={{height: '90px', display: 'block', marginTop: '8px', borderRadius: '6px'}} />
             <FormControl sx={{margin: '12px 0'}} size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={category}
                    label="Select Category"
                    onChange={e=>setCategory(e.target.value)}
                    >
                        {
                            categoriesList.map((cat, i)=><MenuItem key={i}  value={cat.category}>{cat.category}</MenuItem>)
                        }
                </Select>
            </FormControl>

            <TextField value={bheader} onChange={e=>setBheader(e.target.value)} sx={{margin: '12px 0'}} variant='outlined' label="Enter Heading" fullWidth size='small'/>
            <textarea value={shortIntro} onChange={e=>setShortIntro(e.target.value)} className='rd-textarea' placeholder='Enter Short Intro' rows={4}>

            </textarea>
            <Editor
                    toolbar={config}
                    editorState={editorState}
                    placeholder='Type your blog here...'
                    onEditorStateChange={onEditorStateChange}
                />

                <LoadingButton onClick={submitData} startIcon={<SaveIcon />} loadingPosition='start' loading={submitting}  variant='outlined' fullWidth sx={{margin: '12px 0'}}>Submit</LoadingButton>
             </div>
        </div>
    );
};

export default AddBlog;