import React, {useState, useEffect, useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { cartDetails, updateCart, uploadRecieptCart } from '../redux/actions/cartActions';
import { CART_UPDATE_RESET } from '../redux/constants/cartConstants';

const CartDetails = () => {
  const {id} = useParams()

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [customerName, setCustomerName] = useState('')
  const [items, setItems] = useState([])
  const [paymentStatus, setPaymentStatus] = useState([])
  const [adminApprovalStatus, setAdminApprovalStatus] = useState('')
  const [paymentReciept, setPaymentReciept] = useState(5)
  const [transactionId, setTransactionId] = useState('')
  const [paymentDate, setPaymentDate] = useState('')
 
  
  const diseaseRef = useRef(false)

  const userSignIn = useSelector(state => state.userSignIn)
  const {userInfo} = userSignIn

  const {loading, error, cartData} = useSelector(state => state.cartDetails)

  const {success} = useSelector(state => state.updateCart)
 

  useEffect(()=>{
    if(!diseaseRef.current){
      dispatch(cartDetails(id))
      document.getElementById("create-course-form").reset();
    }

    return () => {
      diseaseRef.current = true
    }

  }, [])

  useEffect(()=>{
    if(success){
      dispatch({
        type: CART_UPDATE_RESET
      })

      navigate('/admin/cart')
    }
  },[success])


  useEffect(()=>{
    if(cartData){
        setCustomerName(cartData.customerId && cartData.customerId.name)
        setAdminApprovalStatus(cartData.adminApprovalStatus)
        setItems(cartData.items)
        setPaymentDate(cartData.paymentDate)
        setPaymentReciept(cartData.paymentReciept)
        setPaymentStatus(cartData.paymentStatus)
        setTransactionId(cartData.transactionId)
    }
  }, [cartData])

  const updateCartHandler = (e) => {
    e.preventDefault()
    dispatch(updateCart({
      _id: cartData._id,
      customerId: cartData.customerId._id,
      items,
      paymentDate,
      paymentReciept,
      paymentStatus,
      adminApprovalStatus,
      transactionId  
    }))

    setCustomerName('')
    setItems([])
    setPaymentDate('')
    setTransactionId('')
    setPaymentReciept('')
    setAdminApprovalStatus('')
    setPaymentStatus('')
  }

  // const paymentRecieptHandler = (file) => {
  //   setPaymentReciept({})
  //   const formData = new FormData()
  //   formData.append('paymentReceipt',file)
  //   dispatch(uploadRecieptCart(cartData._id,formData))
  // }


  return (
    <div>
        <div className='container p-5'>
            <h1>Cart Information</h1>
            <form className='row mt-3' onSubmit={updateCartHandler} id="create-course-form">
                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="company"> Customer Name</label>
                    <input className="form-control" disabled type="text" placeholder='Product Name' id='product' defaultValue={customerName} onChange={(e)=> setCustomerName(e.target.value)} />
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="adminApprovalStatus"> Admin Approval Status</label>
                <select className="form-select" id='adminApprovalStatus' value={adminApprovalStatus && adminApprovalStatus} onChange={(e) => setAdminApprovalStatus(e.target.value)} >
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="Rejected">Rejected</option>
                </select>
                </div>

                <div className='col-6 mb-3'>
                  <label className="form-label" htmlFor="paymentStatus"> Payment Status</label>
                  <select className="form-select" id='paymentStatus' value={paymentStatus && paymentStatus} onChange={(e) => setPaymentStatus(e.target.value)} >
                  <option value="Pending">Pending</option>
                  <option value="Completed">Completed</option>
                </select>                </div>

                {/* <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="adminApprovalStatus">Payment Date</label>
                <input className="form-control" type="text" placeholder='adminApprovalStatus' id='manufacturing' defaultValue={paymentDate} onChange={(e)=> setPaymentDate(e.target.value)}/>
                </div> */}

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="date"> Payment date</label>
                <input className="form-control" type="date" min={new Date().toISOString()}  id='date' defaultValue={paymentDate} onChange={(e)=> setPaymentDate(e.target.value)}/>
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="transactionId">Transaction Id</label>
                <input className="form-control" type="text" placeholder='Transaction Id' id='transactionId' defaultValue={transactionId} onChange={(e)=> setTransactionId(e.target.value)}/>
                </div>

                <div className='col-6 mb-3'></div>


                {/* <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="payment reciept"> Payment Reciept</label>
                <input className="form-control" type="file"  id='payment reciept' multiple onChange={(e) => paymentRecieptHandler(e.target.files[0]) }/>
                </div> */}

                {paymentReciept &&<div>
                 <h3>Purchase Reciept</h3>
                    <div className="row">
                    
                      <div className='col-2 mb-3 image-container'>
                          {/* <button className='close-btn'><i className="fa-solid fa-xmark"></i></button> */}
                          <img src={paymentReciept.secureUrl} alt="" className='img-fluid'/>
                      </div>
                           
                    
                    </div>
                </div>
                            }

                
                { (items && items.length>0) && 
                <>
                <div className='col-12 mb-3'>
                    <h3 className='mb-3 mt-2'>Cart Items</h3>
                </div>

                { items.map((item,i) => (
                    <>
                    <div className='col-4 mb-3'>
                        <img src={item.medicineId? item.medicineId.previewImage && item.medicineId.previewImage.secureUrl : item.labTestId? item.labTestId.previewImage && item.labTestId.previewImage.secureUrl : item.ayurvedaId.previewImage && item.ayurvedaId.previewImage.secureUrl} alt={item.medicineId? item.medicineId.name : item.labTestId? item.labTestId.name : item.ayurvedaId.name} className="img-fluid" />
                    </div>
                    <div className='col-8 mb-3'>
                        <h5>Product Name: {item.medicineId? item.medicineId.name : item.labTestId? item.labTestId.name : item.ayurvedaId.name}</h5>
                        <h5>Quantity: {item.quantity}</h5>
                    </div>
                    </>
                ))
                }
                </>
                }

                <div>
                    <button type='submit' className='btn btn-primary mt-3'> Update Cart</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default CartDetails