import React, {  } from 'react'; 
import { Routes, Route  } from "react-router-dom";
import AdminLogin from './admin-pages/AdminLogin';
import NotFoundPage from './pages/NotFoundPage'; 
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';

import Doctors from './pages/Doctors'; 

import Blog from './pages/Blog'; 

import Dashboard from './admin-pages/Dashboard';
import UserList from './admin-pages/UserList';
import AddCompany from './admin-pages/AddCompany';
import AddMedicine from './admin-pages/AddMedicine';
import CompanyList from './admin-pages/CompanyList';
import Admin from './admin-pages/Admin';
import AddDoctor from './admin-pages/AddDoctor'; 
import DoctorList from './admin-pages/DoctorList';
import UserDetails from './admin-pages/UserDetails';
import CompanyDetails from './admin-pages/CompanyDetails';
import AddLabTest from './admin-pages/AddLabTest';
import AddAyurveda from './admin-pages/AddAyurveda';
import MedicineList from './admin-pages/MedicineList';
import LabTestList from './admin-pages/LabTestList';
import AyurvedaList from './admin-pages/AyurvedaList';
import OrderList from './admin-pages/OrderList';
import LabTestDetails from './admin-pages/LabTestDetails';
import AyurvedaDetails from './admin-pages/AyurvedaDetails';
import MedicineDetails from './admin-pages/MedicineDetails';
import CartList from './admin-pages/CartList';
import CartDetails from './admin-pages/CartDetails';
import OrderView from './admin-pages/OrderView';
import AddMember from './admin-pages/AddMember';
import MemberList from './admin-pages/MemberList';
import AdminProfile from './admin-pages/AdminProfile';
import DoctorDetails from './admin-pages/DoctorDetails';
import PrivatePath from './privateRoute';
import Diseases from './admin-pages/Diseases';
import AddDisease from './admin-pages/AddDisease';
import EditDisease from './admin-pages/EditDisease';
import AddBanner from './admin-pages/AddBanner';
import ManageBlog from './admin-pages/ManageBlog';

// import PreLoader from './components/PreLoader';
// const Dashboard = lazy => import('./admin-pages/Dashboard.jsx')
function App() {
  return (
    <>
          <Routes>
            <Route path="/" element={<PrivatePath><Admin /></PrivatePath>}>
              <Route path="" element={<Dashboard />}></Route>
              <Route path="dashboard" element={<Dashboard />}></Route>
              <Route path="users" element={<UserList />}></Route>
              <Route path="user/view/:id" element={<UserDetails />}></Route>
              <Route path="company" element={<CompanyList />}></Route>
              <Route path="company/edit/:id" element={<CompanyDetails />}></Route>
              <Route path="doctors" element={<DoctorList />}></Route>
              <Route path="diseases" element={<Diseases />}></Route>
              <Route path="disease/add" element={<AddDisease />}></Route>
              <Route path="disease/edit/:id" element={<EditDisease />}></Route>
              <Route path="company/add" element={<AddCompany />}></Route>
              <Route path="medicines" element={<MedicineList />}></Route>
              <Route path="medicine/add" element={<AddMedicine />}></Route>
              <Route path="medicine/edit/:id" element={<MedicineDetails />}></Route>
              <Route path="labtest/add" element={<AddLabTest />}></Route>
              <Route path="labtest" element={<LabTestList />}></Route>
              <Route path="labtest/edit/:id" element={<LabTestDetails />}></Route>
              <Route path="ayurveda/add" element={<AddAyurveda />}></Route>
              <Route path="ayurveda" element={<AyurvedaList />}></Route>
              <Route path="ayurveda/edit/:id" element={<AyurvedaDetails />}></Route>
              <Route path="doctor/add" element={<AddDoctor />}></Route>
              <Route path="doctor/edit/:id" element={<DoctorDetails />}></Route>
              <Route path="orders" element={<OrderList />}></Route> 
              <Route path="orders/view/:id" element={<OrderView />}></Route>   
              <Route path="cart" element={<CartList />}></Route>
              <Route path="add-banner" element={<AddBanner/>}></Route>
              <Route path="manage-blog" element={<ManageBlog/>}></Route>
              <Route path="cart/edit/:id" element={<CartDetails />}></Route>
              <Route path="member/add" element={<AddMember />}></Route>
              <Route path="member" element={<MemberList />}></Route>   
              <Route path="admin-profile" element={<AdminProfile />}></Route>   
            </Route>
            {/* <Route path='/add-blog' element={<AddBlog />} ></Route> */}
            
            <Route path="/login" element={<AdminLogin />}></Route>

            <Route path="/error" element={<NotFoundPage />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route path="/reset-password" element={<ResetPassword />}></Route>
            {/* <Route path="/dashboard" element={<Dashboard />}></Route> */}
            <Route path="/doctors" element={<Doctors />}></Route>
            <Route path="/blog" element={<Blog />}></Route>
        </Routes>
    </>
  );
}

export default App;



