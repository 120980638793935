import React, {useMemo, useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import MaterialReactTable from 'material-react-table';
import { orderList } from '../redux/actions/orderActions';
import PreLoader from '../components/PreLoader';
import axios from 'axios';
import { API_ENDPOINT } from '../rd-utils/constants';
import { getHeader } from '../rd-utils/util';
import CustomCircularP from '../rd-pages/widgets/customCircularP';
import OrderItems from './OrderItems';
import OrderReceipt from './OrderReceipt';
import { toast } from 'react-toastify';

const OrderList = () => {
  const  ORDERS = "Orders"
  const ORDER_ITEMS = "Ordered Items"
  const RECEIPT = "Receipt"

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(ORDERS)
  const [currentOrder, setCurrentOrder] = useState({})


  useEffect(()=>{
    loadOrders()
  }, [])
  function loadOrders(){
    setLoading(true)
    axios.get(API_ENDPOINT+'admin/get-all-orders', getHeader()).then(res=>{
      setLoading(false)
      let d = res.data
      console.log(d)
      if(d.success){
        setOrders(d.message)
      }
    })  
  }
  const showItems = (oid)=>{
    setCurrentOrder(oid)
    setCurrentPage(ORDER_ITEMS)
  }
  const back = ()=>{
    setCurrentPage(ORDERS)
  }
  const viewReceipt = (od)=>{
    if(od.receipt){
      setCurrentOrder(od)
      setCurrentPage(RECEIPT)
    }else{
      toast("Nothing to view")
    }
  }
  const columns = useMemo(
    () => [
      {
        accessorKey: 'order_id', 
        header: 'Order id',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'userName', //simple recommended way to define a column
        header: 'Customer Name',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'userEmail', //simple recommended way to define a column
        header: 'Email Id',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'status', //simple recommended way to define a column
        header: 'Status',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'view', //simple recommended way to define a column
        header: 'Receipt',
        Cell: ({ cell, row }) => (
          <button disabled={!cell.row.original.receipt} onClick={()=>viewReceipt(cell.row.original)} className='btn btn-primary'>{cell.row.original.receipt?'View Receipt':'NA'}</button>
        )
      },
      {
        accessorKey: 'view', 
        header: 'View Items',
        Cell: ({ cell, row }) => (
          <button className='btn btn-primary' onClick={() =>showItems(cell.row.original)}>View Items</button>
        ),
      },
      
    ],
    [],
  );

  return (
    <div className='container my-3'>
      <CustomCircularP show={loading}/>
      <div className='p-3'>
      <h1 className='heading'>{currentPage}</h1>
      </div>
    
      {currentPage === ORDERS?
        <MaterialReactTable 
              columns={columns} 
              data={orders} 
              enableColumnOrdering //enable some features
              enableRowSelection 
              enableStickyHeader
              // onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
              // state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
              />
            : currentPage === ORDER_ITEMS?
            <OrderItems order={currentOrder}  back={back}/>:
            <OrderReceipt order={currentOrder} back={back}/>
        }
    </div>

  )
}

export default OrderList