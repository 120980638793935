import { Button, IconButton } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import EditBlog from './EditBlog';
import { API_ENDPOINT } from '../rd-utils/constants';
import axios from 'axios';
import { getDateFormat, getHeader } from '../rd-utils/util';
import CustomCircularP from '../rd-pages/widgets/customCircularP';
import { useEffect } from 'react';
import BlogDetail from './BlogDetail';
import AddBlog from './AddBlog';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import YesNoDialog from '../rd-pages/YesNoDialog';
import { toast } from 'react-toastify';
import BlogEdit from './BlogEdit';

const ManageBlog = () => {
    const ADD_BLOG = "add_blog"
    const EDIT_BLOG = "edit_blog"
    const BLOG_DETAIL = "blog_detail"
    const BLOGS = "blogs"

    const [currentView, setCurrentView] = useState(BLOGS)
    const [loadingBlogs, setLoadingBlogs] = useState(false)
    const [currentBlog, setCurrentBlog] = useState({})
    const [blogs, setBlogs] = useState([])


    const [dopen, setDopen]= useState(false)
    const [cid, setCid] = useState("")
    const onYesResponse = ()=>{
      setDopen(false)
      //dispatch(deleteMedicine(cid))
      deleteBlog(cid)
    }
    const onNoResponse = ()=>{
      setDopen(false)
    }
    function deleteBlog(id){
      setLoadingBlogs(true)
      axios.post(API_ENDPOINT+'admin/delete-blog', {id}, getHeader()).then(res=>{
        setLoadingBlogs(false)
         let d = res.data
         if(d.success){
           window.location.reload()
         }else{
           toast(d.message)
         }
      })
   }
    const confirmDelete = (id)=>{
       setDopen(true)
       setCid(id)
    }

    const back = ()=>{
        setCurrentView(BLOGS)
    }
    useEffect(()=>{
        getBlogs()
    }, [])
    function getBlogs(){
        setLoadingBlogs(true)
        axios.get(API_ENDPOINT+'admin/get-blogs', getHeader()).then(res=>{
            setLoadingBlogs(false)
            let d = res.data
            if(d.success){
                setBlogs(d.message)
            }
        })
    }

    const onBlogAdded = (blogO)=>{
        const bls = [...blogs]
        bls.push(blogO)
        setBlogs(bls)
    }
    const goBlogDetail = (blog)=>{
        setCurrentBlog(blog)
        setCurrentView(BLOG_DETAIL)
    }
    const goBlogEdit = (blog)=>{
        setCurrentBlog(blog)
        setCurrentView(EDIT_BLOG)
    }
    return (
        <div style={{margin: '12px', paddingRight: '16px'}}>
              <YesNoDialog dopen={dopen} setDopen={setDopen} onYesResponse={onYesResponse} onNoResponse={onNoResponse} message="Are you sure you want to delete this?" />
               <CustomCircularP show={loadingBlogs}/>
            {currentView === BLOGS?
                <>
            <div style={{display: 'flex', margin: '8px 0'}}>
                <div style={{flex: 1}}></div>
                <div>
                    <Button onClick={()=>setCurrentView(ADD_BLOG)} startIcon={<AddIcon />} variant='outlined'>Add Blog</Button>
                </div>
            </div>
            <div className="rd-table-container">
                        <table>
                        <colgroup>
                            <col span="1"  style={{width: '1%'}}/>
                            <col span="1" style={{width: '12%'}}/>
                            <col span="1" style={{width: '30%'}} />
                            <col span="1" style={{width: '15%'}} />
                            <col span="1" style={{width: '15%'}} />
                            {/* <col span="1" style={{width: '8%'}} />
                            <col span="1" style={{width: '8%'}} /> */}
                            <col span="1" style={{width: '10%'}} />
                        </colgroup>
                            <tbody>
                            <tr>
                                <th>SI.no.</th>
                                <th>Image</th>
                                <th>Heading</th>
                                <th>Category</th>
                                <th>createdOn</th>
                                <th>Edit</th>
                                <th>Delete</th>
                                <th>Details</th>
                            </tr>

                            {
                                blogs.map((blog, i)=><tr key={i}>
                                    <td>{i+1}</td>
                                    <td><img src={blog.image} alt={i} style={{height: '76px', borderRadius: '8px'}}/></td>
                                    <td>{blog.header}</td>
                                    <td>{blog.category}</td>
                                    <td>{getDateFormat(new Date(blog.createdOn))}</td>
                                    <td>
                                    <IconButton onClick={()=>goBlogEdit(blog)}  aria-label="delete">
                                        <EditIcon />
                                    </IconButton>
                                    </td>
                                    <td>
                                    <IconButton onClick={()=>confirmDelete(blog._id)} aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                    </td>
                                    <td><Button onClick={()=>goBlogDetail(blog)} variant='outlined' size='small'>Detail</Button></td>
                                </tr>)
                            }
                            

                            </tbody>
                        </table>
                    </div>
                </>
                :currentView === ADD_BLOG?
                <AddBlog onBlogAdded = {onBlogAdded} back={back}/>
                :currentView === EDIT_BLOG?
                <BlogEdit blog={currentBlog} back={back}/>
                :currentView === BLOG_DETAIL?
                <BlogDetail blog={currentBlog} back={back}/>
                :<></>
            }
        </div>
    );
};

export default ManageBlog;