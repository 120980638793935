import React, { useState } from 'react'
import {Link, useNavigate} from 'react-router-dom';

export default function ForgotPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const submit = () => {
        //navigate('/reset-password');
    }

  return (
    <>
     <div className="container my-5">
        <div className="row justify-content-center">
            <div className="col-md-5">
                <div className="card">
                    <div className="card-body">
                        <div className='mb-5'>
                            <h3 className='text-center fw-bolder custom-text-color'>Forgot Password ?</h3>
                            <p className='text-muted text-center fw-lighter'>Enter your email below to recive your password reset instructions</p>
                        </div>
                        <div className="form-floating mb-4">
                            <input type="email" className="form-control" id="floatingInput" value={email} onChange={(e)=>setEmail(e.target.value)} />
                            <label htmlFor="floatingInput" className='fw-lighter'>Email address</label>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-blue-style w-100" onClick={submit}>Send</button>
                            <p className='my-3 fw-lighter text-muted'><span className=''><Link to={{pathname:"/login"}}><i className="fa-solid fa-angle-left"></i> Back to login page</Link></span></p>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    </>
  )
}
