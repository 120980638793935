import axios from 'axios'
import React,  {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import CustomCircularP from '../rd-pages/widgets/customCircularP'
import { API_ENDPOINT } from '../rd-utils/constants'
import { getHeader } from '../rd-utils/util'
import { addCompany} from '../redux/actions/companyActions'

const AddCompany = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [companyName, setCompanyName] = useState('')
    const [personName, setPersonName] = useState('')
    const [emailId, setEmailId] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)

    const addCompanyHandler = (e) => {
        e.preventDefault()
        setLoading(true)
        let params = {name: companyName, contactPerson: personName, mailAddress: emailId, phoneNumber: phoneNumber, description: description}
        axios.post(API_ENDPOINT+'admin/add-company', params, getHeader()).then(res=>{
          setLoading(false)
          let d = res.data
          if(d.success){
            navigate('/company')
          }else{
            toast(d.message)
          }
        })
    }

  return (
    <div>
      <CustomCircularP show={loading}/>
        <div className='container p-5'>

            <h1>Company Information</h1>
            <form className='row mt-3' onSubmit={(e) =>addCompanyHandler(e)}>
                <div className='col-6 mb-3'>
                <label htmlFor="companyName" className="form-label"> Company Name</label>
                <input className="form-control" type="text" placeholder='Company Name' value={companyName} id='companyName' onChange={(e)=>setCompanyName(e.target.value)} required/>
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="personName"> Person Name</label>
                <input className="form-control" type="text" placeholder='Person Name' id='personName' value={personName} onChange={(e)=>setPersonName(e.target.value) } required/>
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="emailId"> Email Id</label>
                <input className="form-control" type="email" placeholder='Email Id' id='emailId' value={emailId} onChange={(e)=>setEmailId(e.target.value) } required/>
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="phone"> Phone Number</label>
                <input className="form-control" type="tel" maxLength={'10'} minLength={10} value={phoneNumber} placeholder='Phone Number' id='phone' onChange={(e)=>{
                    if(e.target.value && e.target.value.match("[0-9]+")){
                    setPhoneNumber(e.target.value.match("[0-9]+")[0])
                  }else if(!e.target.value){
                    setPhoneNumber(e.target.value)
                  }} } required/>
                </div>

                <div className='col-12 mb-3'>
                <label className="form-label" htmlFor="description"> Description</label>
                <textarea className="form-control" id="description" rows="3" value={description} onChange={(e)=>setDescription(e.target.value) } required></textarea>
                </div>

                <div>
                    <button type='submit' className='btn btn-primary mt-3'> Add Company</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default AddCompany