import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams}  from 'react-router-dom'
import PreLoader from '../components/PreLoader'
import { orderDetailsById } from '../redux/actions/orderActions'
import { Alert } from 'react-bootstrap'

const OrderView = () => {
    const {id} = useParams()
    const dispatch = useDispatch()

    const {loading, error, orderData} = useSelector(state => state.orderDetails)

    useEffect(() => {
        dispatch(orderDetailsById(id))
    }, [])
    


    if(loading){
        return(
            <div className='loader-position'>
            <PreLoader />
            </div>)
    }

    if(error){
        return( 
        <Alert variant="danger">
          {error}
        </Alert>)
    }

    if(orderData && orderData.status == 400){
        return ( 
        <Alert variant="danger">
            {orderData.message}
        </Alert>)
    }

    

  return (
        <div className='container p-5'>
            <h1>Order Information</h1>
            <form className='row mt-3' id="create-course-form">
                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="company"> Customer Name</label>
                    <input className="form-control" readOnly type="text" placeholder='Product Name' id='product' value = {(orderData && orderData.cartId) && orderData.cartId.customerId && orderData.cartId.customerId.name}/>
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="adminApprovalStatus"> Admin Approval Status</label>
                <input className="form-control" readOnly type="text" placeholder='Admin Approval Status' id='adminApprovalStatus' value = {(orderData && orderData.cartId) && orderData.cartId.adminApprovalStatus}/>
                </div>

                <div className='col-6 mb-3'>
                  <label className="form-label" htmlFor="paymentStatus"> Payment Status</label>
                  <input className="form-control" readOnly type="text" placeholder='Payment Status' id='paymentStatus' value = {(orderData && orderData.cartId) && orderData.cartId.paymentStatus}/>

                                </div>

                {/* <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="adminApprovalStatus">Payment Date</label>
                <input className="form-control" type="text" placeholder='adminApprovalStatus' id='manufacturing' value = {(orderData && orderData.cartId) &&paymentDate} onChange={(e)=> setPaymentDate(e.target.value)}/>
                </div> */}

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="date"> Payment date</label>
                <input className="form-control" readOnly type="text"  id='date' value = {(orderData && orderData.cartId) &&(orderData.cartId.paymentDate)} />
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="transactionId">Transaction Id</label>
                <input className="form-control" readOnly type="text" placeholder='Transaction Id' id='transactionId' value = {(orderData && orderData.cartId) && orderData.cartId.transactionId}/>
                </div>

                <div className='col-6 mb-3'></div>


                {/* <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="payment reciept"> Payment Reciept</label>
                <input className="form-control" type="file"  id='payment reciept' multiple onChange={(e) => paymentRecieptHandler(e.target.files[0]) }/>
                </div> */}

                {(orderData && orderData.cartId) && orderData.cartId.paymentReciept &&<div>
                 {/* <h3>Purchase Reciept</h3> */}
                    <div className="row">
                    
                      <div className='col-2 mb-3 image-container'>
                          {/* <button className='close-btn'><i class="fa-solid fa-xmark"></i></button> */}
                          <img src={orderData && orderData.cartId && orderData.cartId.paymentReciept && orderData.cartId.paymentReciept.secureUrl} alt="" className='img-fluid'/>
                      </div>
                           
                    
                    </div>
                </div>
                            }

                
                { (orderData && orderData.cartId.items && orderData.cartId.items.length>0) && 
                <>
                <div className='col-12 mb-3'>
                    <h3 className='mb-3 mt-2'>Cart Items</h3>
                </div>

                { orderData.cartId.items.map((item,i) => (
                    <>
                    <div className='col-4 mb-3'>
                    <img src={item.medicineId? item.medicineId.previewImage && item.medicineId.previewImage.secureUrl : item.labTestId? item.labTestId.previewImage && item.labTestId.previewImage.secureUrl : item.ayurvedaId.previewImage && item.ayurvedaId.previewImage.secureUrl} alt={item.medicineId? item.medicineId.name : item.labTestId? item.labTestId.name : item.ayurvedaId.name} className="img-fluid" />
                    </div>
                    <div className='col-8 mb-3'>
                        <p>Product Name: {item.medicineId? item.medicineId.name : item.labTestId? item.labTestId.name : item.ayurvedaId.name}</p>
                        <p>Quantity: {item.quantity}</p>
                    </div>
                    </>
                ))
                }
                </>
                }

                {/* <div>
                    <button type='submit' className='btn btn-primary mt-3'> Update Cart</button>
                </div> */}
            </form>
        </div>

  )
}

export default OrderView