import React, {useState, useEffect, useRef} from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import Select from 'react-select';
import axios from 'axios';
import { API_ENDPOINT } from '../rd-utils/constants';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { getHeader } from '../rd-utils/util';

const AddMedicine = () => {

  const navigate = useNavigate()

  const [company, setCompany] = useState('')
  const [expireDate, setExpireDate] = useState('')
  const [productName, setProductName] = useState('')
  const [manufacturerName, setManufacturerName] = useState('')
  const [companies, setCompanies] = useState([])

  const [certs, setCerts] = useState([])
  const [images, setImages] = useState([])
  const [defaultImage, setDefaultImage] = useState('')
  const [certLoading, setCertLoading] = useState(false)
  const [imgLoading, setImgLoading] = useState(false)


  const cert_file = useRef()
  const image_file = useRef()

  const [rating, setRating] = useState(5)
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState(() =>[{ value: 'Supplements', label: 'Supplements' }])
  const [subCategory, setSubCategory] = useState([])
  const [selectedDisease, setSelectedDisease] = useState([])
  const [diseases, setDiseases] = useState([])

  const [diseaseOption, setDiseaseOption] = useState([])
  const [homeScreen, setHomeScreen] = useState(false)
  const [subCategoryOptions, setSubCategoryOptions] = useState([
    { value: 'Men', label: 'Men' },
    { value: 'Women', label: 'Women' },
    { value: 'Kids', label: 'Kids' },
  ])
   

  useEffect(()=>{
    loadCompanies()
    loadDiseases()
  }, [])
  function loadCompanies(){
    axios.get(API_ENDPOINT+'user/get-companies').then(res=>{
        let d = res.data
        if(d.success){
          setCompanies(d.message)
        }
    })
  }
  function loadDiseases(){
    axios.get( API_ENDPOINT+'user/get-diseases').then(res=>{
      let d = res.data
      if(d.success){
        let ds = d.message
        let dA = []
        ds.map(d=>{
          dA.push({value: d.name, label: d.name})
        })
        setDiseases(dA)
      }
  })
  }

  const categoryOptions = [
    { value: 'Supplements', label: 'Supplements' },
    { value: 'Sexual Wellness', label: 'Sexual Wellness' },
    { value: 'Beauty', label: 'Beauty' },
    { value: 'Generic', label: 'Generic' },
  ];

  const categoryHandler = (value) => {
    setCategory([value])
    setSubCategory([])
    if(value.value === 'Sexual Wellness'){
      setSubCategoryOptions([
        { value: 'Condoms', label: 'Condoms' },
        { value: 'Gellies', label: 'Gellies' },
        { value: 'Lubricants', label: 'Lubricants' },
        { value: 'Sexual Health', label: 'Sexual Health' },
      ])
    }else if (value.value === 'Beauty'){
      setSubCategoryOptions([
        { value: 'Hair Care', label: 'Hair Care' },
        { value: 'Skin Care', label: 'Skin Care' },
        { value: 'Personal Care', label: 'Personal Care' },
        { value: 'Perfumes', label: 'Perfumes' },
      ])
      }else if (value.value === 'Generic'){
        setSubCategoryOptions([
          { value: 'Men', label: 'Men' },
          { value: 'Women', label: 'Women' },
          { value: 'Kids', label: 'Kids' },
        ])
        }else{
        setSubCategoryOptions([
          { value: 'Men', label: 'Men' },
          { value: 'Women', label: 'Women' },
          { value: 'Kids', label: 'Kids' },
        ])
    }
  }

  const subCategoryHandler = async(value) => {
    let subCat = []
    await value.forEach(v => subCat.push(v))
    setSubCategory(subCat)
  }

  const addMedicineHandler = (e) => {
    e.preventDefault()
   if(images.length === 0){
      toast("Please add product images")
   }else if(certs.length === 0){
      toast("Please Add certificates")
   }else if(manufacturerName === ''){
    toast("Please Select Manufacturer")
  }else if(defaultImage === ''){
      toast("Please select default image")
   }else{
      let diseaseA = []
      let subcatA = []
      selectedDisease.map(sd=>{
        diseaseA.push(sd.value)
      })
      subCategory.map(sc=>{
        subcatA.push(sc.value)
      })
      let pimages = [...images]
      pimages.map(pi=>{
        if(pi.url === defaultImage){
          pi.isDefault = true
        }else{
          pi.isDefault = false
        }
      })
      
      var formDatas = {
        name: productName,
        diseases: diseaseA,
        expiryDate: expireDate,
        manufacturer: manufacturerName,
        description: description,
        category: category[0].value,
        subCategory: subcatA,
        rating:rating,
        displayAtHome:homeScreen,
        images:images,
        certificates:certs,
        type: 'medicine',
      }
      axios.post(API_ENDPOINT+'admin/add-medicine', formDatas, getHeader()).then(res=>{
        let d = res.data
        if(d.success){
          toast(d.message)
          navigate('/medicines')
        }else{
          toast(d.message)
        }
      })

      
   }
  }


const onImgSelect = event=>{
  const [file] = cert_file.current.files
  if(file){
      let fd = new FormData()
      fd.append("image", file)
      setCertLoading(true)
      axios.post(API_ENDPOINT+'admin/upload-image-only', fd, getHeader()).then(res=>{
          let d = res.data
          setCertLoading(false)
          if(d.success){
              let ca = [...certs]
              ca.push({url: d.message})
              setCerts(ca)
          }else{
              toast(d.message)
          }
      })
  
  }
}
const onImgSelectImage = event=>{
  const [file] = image_file.current.files
  if(file){
      let fd = new FormData()
      fd.append("image", file)
      setImgLoading(true)
      axios.post(API_ENDPOINT+'admin/upload-image-only', fd, getHeader()).then(res=>{
          let d = res.data
          setImgLoading(false)
          if(d.success){
            let ca = [...images]
            ca.push({url: d.message, isDefault: false})
            setImages(ca)
          }else{
              toast(d.message)
          }
      })
  
  }
}
const chooseCert = ()=>{
   cert_file.current.click()
}
const chooseImage = ()=>{
  image_file.current.click()
}
const removeImage = (url)=>{
  let ims = [...images]
  let ind = ims.findIndex(im => im.url === url)
  ims.splice(ind, 1)
  setImages(ims)
}
const removeCert = (url)=>{
  let ims = [...certs]
  let ind = ims.findIndex(im => im.url === url)
  ims.splice(ind, 1)
  setCerts(ims)
}
  return (
    <div>
        <input onChange={onImgSelect} ref={cert_file} type="file" accept="image/*" style={{display: 'none'}} />
        <input onChange={onImgSelectImage} ref={image_file} type="file" accept="image/*" style={{display: 'none'}} />
        <div className='container p-5'>
            <h1>Add Medicine</h1>
            <form className='row mt-3' onSubmit={addMedicineHandler}>
                <div className='col-6 mb-3'>
                <label htmlFor="disease" className="form-label">Drug Category* (Disease)</label>
  
                 <Select
                  value={selectedDisease}
                  onChange={(e) => setSelectedDisease(e)}
                  options={diseases}
                  isMulti
                  required
                />

                </div>
                <div className="col-6">
                <label className="form-label" htmlFor="company"> Display in Home page*</label>
                <select className="form-select" aria-label="Default select example" id='company' value={homeScreen} onChange={(e) => setHomeScreen(e.target.value)}>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
                </div>

                <div className='col-6 mb-3'>
                  <label className="form-label" htmlFor="product"> Product Name*</label>
                  <input className="form-control" required type="text" placeholder='Product Name' id='product' value={productName} onChange={(e)=> setProductName(e.target.value)}/>
                </div>

                <div className='col-6 mb-3'>
                  <label className="form-label" htmlFor="company"> Company Name* </label>
                  <select  className="form-select" required aria-label="Default select example" id='company' value={manufacturerName} onChange={(e)=>setManufacturerName(e.target.value)} >
                    <option selected>Select Company</option>
                    {companies.map((c,i) => {
                      return <option key={i} value={c.name}>{c.name}</option>
                      })}
                  </select>
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="date"> Expiry date*</label>
                <input className="form-control" required type="date" min={new Date().toISOString()}  id='date' value={expireDate} onChange={(e)=> setExpireDate(e.target.value)}/>
                </div>

                <div className='col-6 mb-3'>
                  <label className="form-label" htmlFor="product"> Category*</label>
                  
                <Select
                  value={category}
                  onChange={(e) => categoryHandler(e)}
                  options={categoryOptions}
                  required
                />
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="manufacturing"> Sub Category*</label>
                
                <Select
                  value={subCategory}
                  onChange={(e) => subCategoryHandler(e)}
                  options={subCategoryOptions}
                  isMulti
                  required
                />

                 </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="company"> Ratings</label>
                <select className="form-select" aria-label="Default select example" id='company' value={rating} onChange={(e) => setRating(e.target.value)} required>
                  <option value="5">5</option>
                  <option value="4">4</option>
                  <option value="3">3</option>
                  <option value="2">2</option>
                  <option value="1">1</option>
                </select>
                </div>


                <div className='col-12 mb-3'>
                <label className="form-label" htmlFor="description"> Description</label>
                <textarea className="form-control" id="description" rows="3" onChange={(e) => setDescription(e.target.value)}></textarea>
                </div>
                <div className='col-6 mb-3'>
                <LoadingButton onClick={chooseCert} startIcon={<AddAPhotoIcon />} loading={certLoading} loadingPosition='start' variant='contained' >Add Certificate</LoadingButton>
                </div>
                <div className="row">
                  {
                    certs.map((img, i)=>(
                      <div className='col-2 mb-3 image-container'>
                                    <button onClick={()=>removeCert(img.url)} type='button' className='close-btn'><i className="fa-solid fa-xmark"></i></button>
                                    <img key={i}  src={img.url} alt="" className='img-fluid'/>
                        </div>
                    ))
                  }
                </div>

                <div className='col-12 mb-3'>
                    <LoadingButton onClick={chooseImage} startIcon={<AddAPhotoIcon />} loading={imgLoading} loadingPosition='start' variant='contained' >Add Product Images</LoadingButton>
                </div>
                <div className="row">
                  {
                    images.map((img, i)=>(
                      <div onClick={()=>setDefaultImage(img.url)} className='col-2 mb-3 image-container' style={{border: img.url === defaultImage? '2px solid green':'initial', cursor: 'pointer'}}>
                                    <button onClick={()=>removeImage(img.url)} type='button' className='close-btn'><i className="fa-solid fa-xmark"></i></button>
                                    <img key={i}  src={img.url} alt="" className='img-fluid'/>
                        </div>
                    ))
                  }
                </div>

                <div>
                    <button type='submit' className='btn btn-primary mt-3'> Add Medicine</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default AddMedicine