import React from 'react'
import ReactLoading from 'react-loading';

export default function PreLoader() {
  return (
    <>
       <ReactLoading type={'spinningBubbles'} color={'#0098D9'} height={'20%'} width={'20%'} />
    </>
  )
}
