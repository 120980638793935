import React from 'react'
import {Link, useNavigate} from 'react-router-dom';

export default function ResetPassword() {
  return (
    <>
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-md-5">
                    <div className="card">
                        <div className="card-body">
                            <div className='mb-5'>
                                <h4 className='text-center fw-bolder custom-text-color'>Reset password to login your account</h4>
                                <p className='text-muted text-center'>Enter your new password below, we're just extra safe</p>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="password" className="form-control" id="floatingPassword" placeholder="Password"/>
                                <label htmlFor="floatingPassword">Password</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="password" className="form-control" id="floatingPassword" placeholder="Password"/>
                                <label htmlFor="floatingPassword">Confirm Password</label>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-blue-style w-100">Reset Password</button>
                                <p className='my-3 fw-lighter text-muted'><span className=''><Link to={{pathname:"/login"}}><i className="fa-solid fa-angle-left"></i> Back to login page</Link></span></p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </>
  )
}
