import React,  {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addDoctor } from '../redux/actions/doctorActions'
import {Editor, EditorState} from 'draft-js';
import { toast } from 'react-toastify';
import { API_ENDPOINT, DESIGNATION_LIST, EMAIL_PATTERN, PLACEHOLDER_IMAGE } from '../rd-utils/constants';
import { LoadingButton } from '@mui/lab';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import axios from 'axios';
import { getHeader } from '../rd-utils/util';
import CustomCircularP from '../rd-pages/widgets/customCircularP';

const AddDoctor = () => {


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [name, setName] = useState('')
    const [designation, setDesignation] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [shortIntro, setShortIntro] = useState('')
    const [description, setDescription] = useState('')
    const [pic, setPic] = useState(PLACEHOLDER_IMAGE)
    const [picLoading, setPicLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const image_file = useRef()
  

    const addDoctorHandler = (e) => {
        e.preventDefault()
        if(name === ''){
          toast("Please Enter name")
        }else if(designation === ''){
          toast("Please Select Designation")
        }else if(email === ''){
          toast("Please Enter Email")
        }else if(!EMAIL_PATTERN.test(email)){
          toast("Please Enter valid Email")
        }else if(pic === PLACEHOLDER_IMAGE){
          toast("Please Choose Doctor Image")
        }else{
            const formData = {
              name: name,
              email: email,
              designation: designation,
              phone:  phone,
              short_intro: shortIntro,
              description: description,
              pic: pic
            }
            setLoading(true)
            axios.post(API_ENDPOINT+'admin/add-doctor', formData, getHeader()).then(res=>{
                let d = res.data
                setLoading(false)
                if(d.success){
                  navigate('/doctors')
                }else{
                  toast(d.message)
                }
            })
        }
        
    }
    const removePic = ()=>{
        setPic(PLACEHOLDER_IMAGE)
    }
    const onImgSelectImage = event=>{
      const [file] = image_file.current.files
      if(file){
          let fd = new FormData()
          fd.append("image", file)
          setPicLoading(true)
          axios.post(API_ENDPOINT+'admin/upload-image-only', fd, getHeader()).then(res=>{
              let d = res.data
              setPicLoading(false)
              if(d.success){
                setPic(d.message)
              }else{
                  toast(d.message)
              }
          })
      
      }
    }
    const chooseImage = ()=>{
        image_file.current.click()
    }

  return (
    <div>
      <CustomCircularP show={loading}/>
        <div className='container p-5'>
        <input onChange={onImgSelectImage} ref={image_file} type="file" accept="image/*" style={{display: 'none'}} />
            <h1>Add Doctor</h1>
            <form className='row mt-3' onSubmit={(e) =>addDoctorHandler(e)}>


                <div className='col-6 mb-3'>
                <label htmlFor="name" className="form-label"> Name</label>
                <input className="form-control" type="text" placeholder='Name' id='name' value={name} onChange={(e)=>setName(e.target.value)} required/>
                </div>

                <div className='col-6 mb-3'>
                  <label className="form-label" htmlFor="company">Select Designation</label>
                  <select  className="form-select" required aria-label="Default select example" id='company' value={designation} onChange={(e)=>setDesignation(e.target.value)} >
                    <option selected value=''>--Select designation--</option>
                    {DESIGNATION_LIST.map((c,i) => {
                      return <option key={i} value={c}>{c}</option>
                      })}
                  </select>
                </div>
                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="emailId"> Email Id</label>
                <input className="form-control" type="email" value={email} placeholder='Email Id' id='emailId' onChange={(e)=>setEmail(e.target.value) } required/>
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="phone"> Phone Number</label>
                <input className="form-control" type="tel" maxLength={'10'} minLength={10} value={phone} placeholder='Phone Number' id='phone' onChange={(e)=>{
                    if(e.target.value && e.target.value.match("[0-9]+")){
                      setPhone(e.target.value.match("[0-9]+")[0])
                    }else if(!e.target.value){
                      setPhone(e.target.value)
                    }
                }} required/>
                </div>

                <div className='col-12 mb-3'>
                <LoadingButton onClick={chooseImage} startIcon={<AddAPhotoIcon />} loading={picLoading} loadingPosition='start' variant='contained' >Select Doctor Photo</LoadingButton>
                <div className='col-2 mb-3 image-container' style={{marginTop: '12px'}}>
                                    <button onClick={()=>removePic()} type='button' className='close-btn'><i className="fa-solid fa-xmark"></i></button>
                                    <img src={pic} alt="" className='img-fluid'/>
                        </div>
                </div>
                <div className='col-12 mb-3'>
                <label className="form-label" htmlFor="description">Short Intro</label>
                <textarea className="form-control" id="description" rows="2" value={shortIntro} onChange={(e)=>setShortIntro(e.target.value) } required></textarea>
                </div>

                <div className='col-12 mb-3'>
                <label className="form-label" htmlFor="description"> Description</label>
                <textarea className="form-control" id="description" value={description} rows="3" onChange={(e)=>setDescription(e.target.value) } required></textarea>
                </div>

                <div>
                    <button type='submit' className='btn btn-primary mt-3'> Add Doctor</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default AddDoctor