import React, {useMemo, useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import MaterialReactTable from 'material-react-table';
import { deleteLabTest, labTestList } from '../redux/actions/labTestActions';
import { LAB_TEST_DELETE_RESET } from '../redux/constants/labTestConstants';
import CustomCircularP from '../rd-pages/widgets/customCircularP';
import axios from 'axios';
import { API_ENDPOINT } from '../rd-utils/constants';
import { getHeader } from '../rd-utils/util';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import YesNoDialog from '../rd-pages/YesNoDialog';

const LabTestList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [labTests, setLabTests] = useState([])


  const [dopen, setDopen]= useState(false)
  const [cid, setCid] = useState("")
  const onYesResponse = ()=>{
    setDopen(false)
    //dispatch(deleteMedicine(cid))
    deleteDisease(cid)
  }
  const onNoResponse = ()=>{
    setDopen(false)
  }
  function deleteDisease(id){
    setLoading(true)
    axios.post(API_ENDPOINT+'admin/delete-labtest', {id}, getHeader()).then(res=>{
       setLoading(false)
       let d = res.data
       if(d.success){
         window.location.reload()
       }else{
         toast(d.message)
       }
    })
 }
  const confirmDelete = (id)=>{
     setDopen(true)
     setCid(id)
  }


  useEffect(() => {
    loadMedicines()
  }, [])
  function loadMedicines(){
    setLoading(true)
    axios.post(API_ENDPOINT+'admin/get-products', {type: 'labtest'}, getHeader()).then(res=>{
      setLoading(false)
      let d = res.data
      if(d.success){
        setLabTests(d.message)
      }
    })
  }
  function deleteDisease(id){
    setLoading(true)
    axios.post(API_ENDPOINT+'admin/delete-labtest', {id}, getHeader()).then(res=>{
       setLoading(false)
       let d = res.data
       if(d.success){
         window.location.reload()
       }else{
         toast(d.message)
       }
    })
 }

 const columns = useMemo(
  () => [
    {
      accessorKey: 'name', 
      header: 'Package Name',
      // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
    },
    {
      accessorKey: 'manufacturer', //simple recommended way to define a column
      header: 'Manufacturer',
      // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
    },
    {
      accessorKey: 'diseases', //simple recommended way to define a column
      header: 'Disease',
      // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
    },
    {
      accessorKey: 'edit', 
      header: 'Edit',
      Cell: ({ cell, row }) => (
        <IconButton onClick={() => (cell.row && cell.row.original) && navigate(`/labtest/edit/${cell.row.original._id}`)} aria-label="delete">
          <EditIcon />
        </IconButton>
        ),
    },
    {
      accessorKey: 'delete', 
      header: 'Delete',
      Cell: ({ cell, row }) => (
        <IconButton onClick={() => (cell.row && cell.row.original) && confirmDelete(cell.row.original._id)} aria-label="delete">
          <DeleteIcon />
        </IconButton>
        ),
    },
    
  ],
  [],
);



  return (
    <div className='container my-3'>
      <CustomCircularP show={loading}/>
      <YesNoDialog dopen={dopen} setDopen={setDopen} onYesResponse={onYesResponse} onNoResponse={onNoResponse} message="Are you sure you want to delete this?" />
      <div className='d-flex justify-content-between align-item-center p-3'>
      <h1 className='heading'>Lab Test List</h1>
      <button type='button' className='btn btn-primary' onClick={()=>navigate('/labtest/add')}>Add Lab Test</button>
      </div>
    
     {labTests && <MaterialReactTable 
      columns={columns} 
      data={labTests} 
      enableColumnOrdering //enable some features
      enableRowSelection 
      enableStickyHeader
      // onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
      // state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
      />}
    </div>
  )
}

export default LabTestList