import React,{useState, useEffect} from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import {Link} from 'react-router-dom'
import siteLogo from '../assets/images/site-logo.png'
import './sidebar.css'
import SideItem from './SideItem';

const Sidebar = (props ={}) => {
    const loc = document.location.href.replace(/\/+$/, "")
    const keysUrl = loc.split('/')
    let linkCode = "dashboard"
    if(keysUrl.length>3)
        linkCode = keysUrl[3]
    return (
        // <div className="sidebar">
        //         <div className="sa-sidebar">
        //             <div className="sidebar_header">
        //                 <Link className="sidebar_logo p-2" to="/">
        //                     <div className="d-flex justify-content-center align-item-center">
        //                         <img src={siteLogo} width="100px" alt="logo" />
        //                         {/* <div className="sa-sidebar-logo__caption">Admin</div> */}
        //                     </div>
        //                 </Link>
        //             </div>
        //             <div className="sa-sidebar__body mt-3" data-simplebar="">
        //                 <ul className="sa-nav sa-nav--sidebar" data-sa-collapse="">
        //                     <li className="sa-nav__section">
        //                         {/* <div className="sa-nav__section-title"><span>Application</span></div> */}
        //                         <ul className=" sa-nav__menu sa-nav__menu--root admin-sidebar">
        //                             <li className={`sa-nav__menu-item sa-nav__menu-item--has-icon ${sidebarPath == '/dashboard'? 'active':''}`}>
        //                                 <Link to="/dashboard" className="sa-nav__link">
        //                                 <i className="fa-solid fa-gauge"></i> 
        //                                     <span className="sa-nav__title"> Dashboard</span>
        //                                 </Link>
        //                             </li>
        //                             <li className={`sa-nav__menu-item sa-nav__menu-item--has-icon ${sidebarPath == '/users'? 'active':''}`}>
        //                                 <Link to="/users" className="sa-nav__link">
        //                                     <i className="fa-solid fa-users"></i>
        //                                     <span className="sa-nav__title"> User List</span>
        //                                 </Link>
        //                             </li>

        //                             <li className={`sa-nav__menu-item sa-nav__menu-item--has-icon ${sidebarPath == '/company'? 'active':''}`}>
        //                                 <Link to="/company" className="sa-nav__link">
        //                                     <i className="fa-solid fa-building"></i>
        //                                     <span className="sa-nav__title"> Company List</span>
        //                                 </Link>
        //                             </li>

        //                             <li className={`sa-nav__menu-item sa-nav__menu-item--has-icon ${sidebarPath == '/medicine'? 'active':''}`}>
        //                                 <Link to="/medicine" className="sa-nav__link">
        //                                     <i className="fa-solid fa-building"></i>
        //                                     <span className="sa-nav__title"> Medicine List</span>
        //                                 </Link>
        //                             </li>

        //                             <li className={`sa-nav__menu-item sa-nav__menu-item--has-icon ${sidebarPath == '/labtest'? 'active':''}`}>
        //                                 <Link to="/labtest" className="sa-nav__link">
        //                                     <i className="fa-solid fa-building"></i>
        //                                     <span className="sa-nav__title"> Lab Test List</span>
        //                                 </Link>
        //                             </li>

        //                             <li className={`sa-nav__menu-item sa-nav__menu-item--has-icon ${sidebarPath == '/ayurveda'? 'active':''}`}>
        //                                 <Link to="/ayurveda" className="sa-nav__link">
        //                                     <i className="fa-solid fa-building"></i>
        //                                     <span className="sa-nav__title"> Ayurveda List</span>
        //                                 </Link>
        //                             </li>

        //                             <li className={`sa-nav__menu-item sa-nav__menu-item--has-icon ${sidebarPath == '/doctors'? 'active':''}`}>
        //                                 <Link to="/doctors" className="sa-nav__link">
        //                                     <i className="fa-solid fa-user-doctor"></i>
        //                                     <span className="sa-nav__title"> Doctors</span>
        //                                 </Link>
        //                             </li>

        //                             <li className={`sa-nav__menu-item sa-nav__menu-item--has-icon ${sidebarPath == '/orders'? 'active':''}`}>
        //                                 <Link to="/orders" className="sa-nav__link">
        //                                     <i className="fa-solid fa-building"></i>
        //                                     <span className="sa-nav__title"> Orders</span>
        //                                 </Link>
        //                             </li>

        //                             <li className={`sa-nav__menu-item sa-nav__menu-item--has-icon ${sidebarPath == '/cart'? 'active':''}`}>
        //                                 <Link to="/cart" className="sa-nav__link">
        //                                     <i className="fa-solid fa-building"></i>
        //                                     <span className="sa-nav__title"> Cart List</span>
        //                                 </Link>
        //                             </li>

        //                             <li className={`sa-nav__menu-item sa-nav__menu-item--has-icon ${sidebarPath == '/member'? 'active':''}`}>
        //                                 <Link to="/member" className="sa-nav__link">
        //                                     <i className="fa-solid fa-users"></i>
        //                                     <span className="sa-nav__title">Members List</span>
        //                                 </Link>
        //                             </li>    
        //                         </ul>
        //                     </li>
        //                 </ul>
        //             </div>
        //         </div>
        //     </div>
        <div className='admin-sidebar'>
              <img src={siteLogo} style={{width: '120px', cursor: 'pointer', display: 'table', margin: 'auto'}} alt="logo" />
<br />
             <SideItem icon={<i className="fa-solid fa-gauge"></i> } isActive={linkCode === 'dashboard'} label='Dashboard' link='/dashboard'/>
             <SideItem icon={<i className="fa-solid fa-users"></i>} isActive={linkCode === 'users'} label='Users' link='/users'/>
             <SideItem icon={<i className="fa-solid fa-building"></i> } isActive={linkCode === 'company'} label="Companies" link="/company"/>
             <SideItem icon={<i className="fa-solid fa-building"></i> } isActive={linkCode === 'diseases'} label="Diseases" link="/diseases"/>
             <SideItem icon={<i className="fa-solid fa-building"></i> } isActive={linkCode === 'medicines'} label="Medicines" link="/medicines"/>
             <SideItem icon={<i className="fa-solid fa-building"></i> } isActive={linkCode === 'labtest'} label="Lab test" link="/labtest"/>
             <SideItem icon={<i className="fa-solid fa-building"></i> } isActive={linkCode === 'ayurveda'} label="Ayurveda" link="/ayurveda"/>
             <SideItem icon={<i className="fa-solid fa-user-doctor"></i> } isActive={linkCode === 'doctors'} label="Doctors" link="/doctors"/>
             <SideItem icon={<i className="fa-solid fa-building"></i> } isActive={linkCode === 'orders'} label="Orders" link="/orders"/> 
             <SideItem icon={<i className="fa-solid fa-building"></i> } isActive={linkCode === 'add-banner'} label="Add Banner" link="/add-banner"/>
             <SideItem icon={<i className="fas fa-blog"></i> } isActive={linkCode === 'manage-blog'} label="Blogs" link="/manage-blog"/>                            
        </div>
    )
}

export default Sidebar
