import React, {useState, useMemo, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import MaterialReactTable from 'material-react-table';
import { fetchUsers } from '../redux/actions/userActions';
import PreLoader from '../components/PreLoader';

const MemberList = () => {
  const [members, setMembers] = useState([])
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const {userInfo} = useSelector(state => state.userSignIn)

  const {loading, error, userList} = useSelector(state => state.userList)

  useEffect(() => {
    if(!userInfo || (userInfo && userInfo.userInfo.role !== 'Admin')){
      navigate('/login')
    }else{
    dispatch(fetchUsers())
    }
  }, [])

  useEffect(()=>{
    if(userList){
      setMembers(userList.filter(u => u.role !== 'Customer' && !u.isAdmin))
    }
  }, userList)

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', 
        header: 'Name',
      },
      {
        accessorKey: 'email', 
        header: 'Email Id',
      },
      {
        accessorKey: 'phoneNumber', 
        header: 'Phone Number',
      },
      {
        accessorKey: 'view', 
        header: 'View',
        Cell: ({ cell, row }) => (
          <button className='btn btn-primary' onClick={() => (cell.row && cell.row.original) && navigate(`/admin/user/view/${cell.row.original._id}`)}>View</button>
        ),
      },
      
    ],
    [],
  );

  if(loading){
    return(
    <div className='loader-position'>
      <PreLoader />
    </div>
    )
  }

  if(error){
    return(
    <div className='loader-position'>
      {error}
    </div>
    )
  }



  return (
    <div className='container my-3'>
      <div className='d-flex justify-content-between align-item-center p-3'>
      <h1 className='heading'>Members</h1>
      <button type='button' className='btn btn-primary' onClick={()=>navigate('/admin/member/add')}>Add Member</button>
      </div>
    {members &&
      <MaterialReactTable 
      columns={columns} 
      data={members} 
      enableColumnOrdering 
      enableRowSelection 
      enableStickyHeader
      // onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
      // state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
      />}
    </div>
  )
}

export default MemberList