import draftToHtml from 'draftjs-to-html';
import React from 'react';
import BackBtn from '../rd-pages/widgets/backBtn';
import { getDateFormat } from '../rd-utils/util';
import parse from 'html-react-parser';

const BlogDetail = (props) => {
    const {blog, back} = props
    return (
        <div> 
            <BackBtn onClick={back}/>
            <div style={{margin: '12px auto', maxWidth: '800px', padding: '8px'}}>
                <img src={blog.image} alt={blog.image} style={{borderRadius: '8px', maxHeight: '192px', display: 'block', margin: '4px auto'}}/>
                <h2>{blog.header}</h2>
                <div style={{fontSize: '14px', fontStyle: 'italic'}}>Category: {blog.category}</div>
                <div style={{fontSize: '13px'}}>Posted On: {getDateFormat(new Date(blog.createdOn))}</div>
                <hr />
                <div style={{marginTop: '16px'}}>{parse(blog.desc)}</div>
            </div>
            
        </div>
    );
};

export default BlogDetail;