import React from 'react'
import DoctorDetail from '../../pages/DoctorDetail'
import b1 from '../../assets/images/blog/b1.jpg'
import doc from '../../assets/images/doctor/doc7.jpg'
import { Link, useNavigate } from 'react-router-dom'
export default function DoctorCard(props) {
  return (
    <>
        <div className="col-md-3 my-3" key={props.data.id}>
            <div className="card effect" style={{borderRadius: '10px 20px 30px 40px/30px'}}>
                <div className='text-center mt-2'>
                    <img src={props.data.photo && props.data.photo.secureUrl} alt={props.data.name} style={{height: '30vh'}} />
                </div>
                <div className="card-body" style={{backgroundColor: 'rgb(95 179 237)'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <small className='text-dark m-0'>{props.data.designation}</small>   
                            <h4 className="text-light m-0 fw-bold doc_btn"><Link to="/doctor-detail" state={{ data: props.data }}>{props.data.name.substring(0, 15)}...</Link></h4>
                            <h6 className='text-light m-0 fw-bold doc_btn'>{ props.data.email }</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}