import { LoadingButton } from "@mui/lab"
import React, { useEffect, useRef } from "react"
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useState } from "react";
import { API_ENDPOINT, PLACEHOLDER_IMAGE } from "../rd-utils/constants";
import { getHeader } from "../rd-utils/util";
import axios from "axios";
import { toast } from "react-toastify";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { TextField } from "@mui/material";
import CustomCircularP from "../rd-pages/widgets/customCircularP";
const AddBanner = ()=>{
    const [images, setImages] = useState([])
    const image_file = useRef()
    const [imgLoading, setImgLoading] = useState(false)
    const [choosenImg, setChoosenImg] = useState('')
    const [order, setOrder] = useState(0)
    const [loading, setLoading] = useState(false)

    const onImgSelectImage = event=>{
        const [file] = image_file.current.files
        if(file){
            let fd = new FormData()
            fd.append("image", file)
            setImgLoading(true)
            axios.post(API_ENDPOINT+'admin/upload-image-only', fd, getHeader()).then(res=>{
                let d = res.data
                setImgLoading(false)
                if(d.success){
                  setChoosenImg(d.message)
                }else{
                    toast(d.message)
                }
            })
        
        }
      }
      const chooseImage = ()=>{
        image_file.current.click()
      }
      const removeImage = (url)=>{
        let ims = [...images]
        let ind = ims.findIndex(im => im.url === url)
        ims.splice(ind, 1)
        setImages(ims) 
      }
    const handleUpdate = ()=>{
        if(choosenImg === ''){
            toast("Please Select Image")
        }else if(order === ''){
            toast("Please Enter Order")
        }else{
            let newImage = {
                url: choosenImg,
                order: order
            }
            let imgs = [...images]
            imgs.push(newImage)
            setLoading(true)
            axios.post(API_ENDPOINT+'admin/update-banner', {banners: imgs}, getHeader()).then(res=>{
                let d = res.data
                setLoading(false)
                if(d.success){
                    toast(d.message)
                    setImages(imgs)
                    setChoosenImg('')
                    setOrder(0)
                }else{
                    toast(d.message)
                }
            })
        }
    }
    useEffect(()=>{
        loadBanners()
    }, [])
    function loadBanners(){
        setLoading(true)
        axios.get(API_ENDPOINT+'admin/get-banners', getHeader()).then(res=>{
            let d = res.data
            setLoading(false)
            console.log(d)
            if(d.success){
                setImages(d.message.banners)
            }
        })
    }
    return(
        <div>
          <div className='container p-5'>
          <input onChange={onImgSelectImage} ref={image_file} type="file" accept="image/*" style={{display: 'none'}} />
            <h1>Add Banner</h1>
            <div style={{border: '1px solid #ddd', borderRadius: '8px', padding: '8px', display: 'table'}}>
                <div className='col-12 mb-3'>
                        <LoadingButton onClick={chooseImage} startIcon={<AddAPhotoIcon />} loading={imgLoading} loadingPosition='start' variant='outlined' >Choose Banner Image</LoadingButton>
                        <img src={choosenImg ===''? PLACEHOLDER_IMAGE:choosenImg} alt="cm" style={{height: '144px', marginTop: '8px', display: 'block', boxShadow: '0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)'}}/>
                </div>
                <TextField sx={{marginBottom: '16px'}} type='number' label="Enter Order of Display" size="small" fullWidth value={order} onChange={e=>setOrder(e.target.value)}/>
                <div>
                    <LoadingButton fullWidth variant="contained" startIcon={<TipsAndUpdatesIcon />} loadingPosition='start' loading={loading} onClick={handleUpdate}>Update Banners</LoadingButton>
                </div>
            </div>
            <div className="row">
                  {
                    images.map((img, i)=>(
                      <div  className='col-2 mb-3 image-container' style={{borderRadius: '8px', cursor: 'pointer'}}>
                                    <button onClick={()=>removeImage(img.url)} type='button' className='close-btn'><i className="fa-solid fa-xmark"></i></button>
                                    <img key={i}  src={img.url} alt="" className='img-fluid'/>
                        </div>
                    ))
                  }
                </div>

            </div>
        </div>
    )
}
export default AddBanner