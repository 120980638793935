import React,  {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import { uploadPurchaseRecieptCompany, companyDetails, updateCompany } from '../redux/actions/companyActions'
import {Link, useNavigate, useParams} from 'react-router-dom'
import { COMPANY_UPDATE_RESET, COMPANY_UPLOAD_RESET } from '../redux/constants/companyConstants'
import CustomCircularP from '../rd-pages/widgets/customCircularP'
import axios from 'axios'
import { API_ENDPOINT } from '../rd-utils/constants'
import { toast } from 'react-toastify'
import { getHeader } from '../rd-utils/util'

const CompanyDetails = () => {
    const loc = document.location.href.replace(/\/+$/, "")
    const keysUrl = loc.split('/')
    //console.log(keysUrl)
    const linkCode = keysUrl[5]

    const navigate = useNavigate()
    const [company, setCompany] = useState({
        companyName: "",
        personName: "",
        emailId: "",
        phoneNumber: "",
        description: ""
    })
    // const [companyName, setCompanyName] = useState('')
    // const [personName, setPersonName] = useState('')
    // const [emailId, setEmailId] = useState('')
    // const [phoneNumber, setPhoneNumber] = useState('')
    // const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)
    // const [purchaseReciept, setPurchaseReciept] = useState([])
    // const [deleteReciept, setDeleteReciept] = useState(false)
    const updateCompanyHandler = (e)=>{
        e.preventDefault()
        let toUpdateO={
            id: linkCode,
            name: company.companyName,
            contactPerson: company.personName, 
            mailAddress: company.emailId, 
            phoneNumber: company.phoneNumber, 
            description: company.description
        }
        axios.post(API_ENDPOINT+'admin/update-company', toUpdateO, getHeader()).then(res=>{
            let d = res.data
            if(d.success){
                navigate('/company')
            }else{
                toast(d.message)
            }
        })
    }
    useEffect(()=>{
        loadCompanyDetails()
    }, [])
    function loadCompanyDetails(){
        setLoading(true)
        axios.get(`${API_ENDPOINT}admin/company-details/${linkCode}`, getHeader()).then(res=>{
            setLoading(false)
            let d = res.data
            if(d.success){
                let dt = d.message
                let cdata = {
                    companyName: dt.name,
                    personName: dt.contactPerson,
                    emailId: dt.mailAddress,
                    phoneNumber: dt.phoneNumber,
                    description: dt.description
                }
                setCompany(cdata)
                //setUserData(d.message)
            }else{
                toast(d.message)
            }
        })
    }
    const handleChange = (e) =>{
        const {name, value} = e.target
        console.log(name, value)
        setCompany({
            ...company,
            [name]: value 
        })
    }
  return (
    <div>
        <CustomCircularP show={loading}/>
        <div className='container p-5'>
            <h1>Company Information</h1>
            <form className='row mt-3' onSubmit={(e) =>updateCompanyHandler(e)}>
                <div className='col-6 mb-3'>
                <label htmlFor="companyName" className="form-label"> Company Name</label>
                <input className="form-control" type="text" placeholder='Company Name' id='companyName' name='companyName' value={company.companyName} onChange={handleChange} required/>
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="personName"> Person Name</label>
                <input className="form-control" type="text" placeholder='Person Name' id='personName' name='personName' value={company.personName} onChange={handleChange} required/>
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="emailId"> Email Id</label>
                <input className="form-control" type="email" placeholder='Email Id' id='emailId' name='emailId' value={company.emailId} onChange={handleChange} required/>
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="phone"> Phone Number</label>
                <input className="form-control" type="tel" maxLength={'10'} minLength={10} placeholder='Phone Number' name='phoneNumber' value={company.phoneNumber} id='phone' onChange={handleChange} required/>
                </div>
                <div className='col-12 mb-3'>
                <label className="form-label" htmlFor="description"> Description</label>
                <textarea className="form-control" id="description" rows="3" onChange={handleChange} name="description" value={company.description}  required></textarea>
                </div>

                {/* <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="file"> Purchase reciept</label>
                <input className="form-control" type="file"  id='file' multiple defaultValue={purchaseReciept}/>
                </div> */}

                <div>
                    <button type='submit' className='btn btn-primary mt-3'> Update Company</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default CompanyDetails