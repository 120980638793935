import React, { useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import MaterialReactTable from 'material-react-table';
import { fetchUsers } from '../redux/actions/userActions';

const AdminProfile = () => {
    const navigate = useNavigate()
  return (
    <>
         <div className="container my-3">
            <div className="row">
                <div className="col-md-12">
                    <h1 className='heading'>Admin Profile</h1>

                </div>
            </div>
         </div> 
    </>
  )
}

export default AdminProfile