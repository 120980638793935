import { LowPriority } from "@mui/icons-material"
import { getToken } from "./util"
const LOCAL = "local"
const PRODUCTION="production"
const DESIGNATION_LIST = [
    "Sr.Consultant",
    "Ortho Surgeon",
    "Physician",
    "Consultant",
    "Eye Surgeon",
    "ENT Surgeon",
    "Cardiologist",
    "Anatomical Pathology",
    "Anesthesiology",
    "Cardiovascular/Thoracic Surgery",
    "Clinical Immunology/Allergy",
    "Critical Care Medicine",
    "Dermatology",	
    "Diagnostic Radiology",	
    "Emergency Medicine",
    "Endocrinology and Metabolism",
    "Family Medicine",
    "Gastroenterology",	
    "General Internal Medicine",	
    "General Surgery",
    "General/Clinical Pathology",	
    "Geriatric Medicine",
    "Hematology",
    "Medical Biochemistry",	
    "Medical Genetics",	
    "Medical Microbiology and Infectious Diseases",	
    "Medical Oncology",
    "Nephrology",
    "Neurology",
    "Neurosurgery",	
    "Nuclear Medicine",	
    "Obstetrics/Gynecology",	
    "Occupational Medicine",
    "Ophthalmology",
    "Orthopedic Surgery",
    "Otolaryngology",
    "Pediatrics",
    "Physical Medicine and Rehabilitation (PM & R)",
    "Plastic Surgery",
    "Psychiatry",
    "Public Health and Preventive Medicine (PhPm)",
    "Radiation Oncology",
    "Respirology",
    "Rheumatology",	
    "Urology"
]
const EMAIL_PATTERN = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
const state = PRODUCTION
const PLACEHOLDER_IMAGE = "/placeholder.png"
const API_ENDPOINT = state === PRODUCTION?"https://apis.rkincindia.com/":"http://localhost:5000/"
export {API_ENDPOINT,
    EMAIL_PATTERN,
    DESIGNATION_LIST, PLACEHOLDER_IMAGE}