import React,  {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import img from '../assets/images/doctor-profile-img.jpg'
import {useParams} from 'react-router-dom'
import { fetchUserDetails } from '../redux/actions/userActions'
import PreLoader from '../components/PreLoader'
import Error from '../components/Error'
import axios from 'axios'
import { API_ENDPOINT } from '../rd-utils/constants'
import { toast } from 'react-toastify'
import CustomCircularP from '../rd-pages/widgets/customCircularP'
import { getHeader } from '../rd-utils/util'

const UserDetails = () => {
    const [loading, setLoading] = useState(false)
    const [userData, setUserData] = useState({})


  const loc = document.location.href.replace(/\/+$/, "")
  const keysUrl = loc.split('/')
  const linkCode = keysUrl[5]

  useEffect(()=>{
    loadUserDetails()
  }, [])
  function loadUserDetails(){
    setLoading(true)
    axios.get(`${API_ENDPOINT}admin/user-details/${linkCode}`, getHeader()).then(res=>{
        setLoading(false)
        let d = res.data
        if(d.success){
            setUserData(d.message)
        }else{
            toast(d.message)
        }
    })
  }
      
  return (
    <div>
            <div className='container p-5'>
                <CustomCircularP show={loading} />
            <h1>User Information</h1>
            { userData &&
            <form className='row mt-3'>
                <div className='col-6 mb-3'>
                    <label htmlFor="name" className="form-label"> Name</label>
                    <input disabled className="form-control" type="text" placeholder='Name' id='name' value={userData.name}/>
                </div>

                <div className='col-6 mb-3'>
                    <label className="form-label" htmlFor="emailId"> Email Id</label>
                    <input disabled className="form-control" type="email" placeholder='Email Id' id='emailId' value={userData.email}/>
                </div>

                <div className='col-6 mb-3'>
                    <label className="form-label" htmlFor="phone"> Phone Number</label>
                    <input disabled className="form-control" type="tel" maxLength={'10'} minLength={10} placeholder='Phone Number' id='phone' value={userData.phoneNumber}/>
                </div>                 
                <>
                <h3 className='mb-3 mt-2'>Address</h3>
                <div className='col-6 mb-3'>
                    <label className="form-label" htmlFor="street"> Street</label>
                    <input disabled className="form-control" type="text" placeholder='Street' id='street' value={userData.address && userData.address.street}/>
                </div>

                <div className='col-6 mb-3'>
                    <label className="form-label" htmlFor="city"> City</label>
                    <input disabled className="form-control" type="text" placeholder='City' id='city' value={userData.address && userData.address.city}/>
                </div>

                <div className='col-6 mb-3'>
                    <label className="form-label" htmlFor="state"> State</label>
                    <input disabled className="form-control" type="text" placeholder='State' id='state' value={userData.address && userData.address.state}/>
                </div>

                <div className='col-6 mb-3'>
                    <label className="form-label" htmlFor="country"> Country</label>
                    <input disabled className="form-control" type="text" placeholder='Country' id='country'value={userData.address && userData.address.country}/>
                </div>

                <div className='col-6 mb-3'>
                    <label className="form-label" htmlFor="pincode"> Pincode</label>
                    <input disabled className="form-control" type="number" placeholder='Pincode' id='pincode' value={userData.address && userData.address.pincode }/>
                </div>
                </>
            </form>}
        </div>
    </div>
  )
}

export default UserDetails