import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
// import { userLogin } from '../../redux/actions/userActions';
import { userLogin } from '../redux/actions/userActions';
import {Alert} from 'react-bootstrap';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { concat } from 'lodash';
import axios from 'axios';
import { API_ENDPOINT } from '../rd-utils/constants';
import { toast } from 'react-toastify';
import { saveToken, saveUser } from '../rd-utils/util';

export default function AdminLogin() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

  
    const [user, setUser] = useState({
        email: "",
        password: "",
    })

    const login = () => {
        setLoading(true)
        axios.post(API_ENDPOINT+'admin/login', user).then(res=>{
            let d = res.data
            setLoading(false)
            if(d.success){
                let data = d.message
                let name = data.name
                let email = data.email
                let token = data.token
                saveToken(token)
                saveUser({name, email}) 
                navigate('/')              
            }else{
                toast(d.message)
            }
        })
    }

    const handleChange = (e) =>{
        const {name, value} = e.target
        setUser({
            ...user,
            [name]: value 
        })
    }

  return (
    <>
    <div className="container my-5">
        <div className="row justify-content-center">
            <div className="col-md-5 my-5">
                <div className="card">
                    <div className="card-body" style={{paddingBottom: '32px'}}>
                        <h4 className='text-center fw-bolder custom-text-color mb-4'>Welcome Admin, Sign In to continue</h4>
                        <div className="form-floating mb-4">
                                <input type="email" className="form-control"  placeholder="name@example.com"  name="email" value={user.email} onChange={handleChange}/>
                                <label htmlFor="floatingInput">Email Address</label>
                        </div>
                        <div className="form-floating mb-4">
                                <input type="password" className="form-control"  placeholder="password"  name="password" value={user.password} onChange={handleChange}/>
                                <label htmlFor="floatingInput">Password</label>
                        </div>
                      <LoadingButton loading={loading} loadingPosition='start' variant='contained' fullWidth onClick={login}>Login</LoadingButton>
                      </div>
                </div>
            </div> 
        </div>
    </div>
    </>
  )
}
