import React, {useMemo, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import MaterialReactTable from 'material-react-table';
import { cartList } from '../redux/actions/cartActions';
import PreLoader from '../components/PreLoader';

const CartList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {loading, error, cartItems} = useSelector(state => state.listCart)

  const {userInfo} = useSelector(state => state.userSignIn)


  useEffect(() => {
    if(!userInfo || (userInfo && userInfo.userInfo.role !== 'Admin')){
      navigate('/login')
    }else{
    dispatch(cartList())
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => {
          if(row.customerId){
            return`${row.customerId.name}`
          }
        }, 
        header: 'Person Name',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorFn: (row) => {if(row.customerId){
          return`${row.customerId.email}`
        }}, 
        header: 'Email Id',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorFn: (row) => {
          if(row.customerId){
            return`${row.customerId.phoneNumber}`
          }
        }, 
        header: 'Phone Number',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'paymentStatus', //simple recommended way to define a column
        header: 'Payment Status',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'edit', 
        header: 'Edit',
        Cell: ({ cell, row }) => (
          <button className='btn btn-primary' onClick={() => (cell.row && cell.row.original) && navigate(`/admin/cart/edit/${cell.row.original._id}`)}>edit</button>
        ),
      },
      
    ],
    [],
  );

  if(loading){
    return(
    <div className='loader-position'>
      <PreLoader />
    </div>
    )
  }

  if(error){
    return(
    <div className='loader-position'>
      {error}
    </div>
    )
  }




  return (
    <div className='container my-5'>
      <div className='p-3'>
      <h1 className='heading'>Cart List</h1>
      </div>
    
     {cartItems && <MaterialReactTable 
      columns={columns} 
      data={cartItems} 
      enableColumnOrdering //enable some features
      enableRowSelection 
      enableStickyHeader
      // onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
      // state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
      />}
    </div>
  )
}

export default CartList