const RK_TOKEN = "rk_token"
const RK_ADMIN = "rk_admin"
const removeDuplicatesStringArray = (arr)=>{
    return arr.filter((item,
        index) => arr.indexOf(item) === index);
}
const capitalizeFirstLetter = (string)=>{
    return string.charAt(0).toUpperCase() + string.slice(1);
}
const saveToken = (token)=>{
    localStorage.setItem(RK_TOKEN,token)
}
const saveUser = (user)=>{
    localStorage.setItem(RK_ADMIN, JSON.stringify(user))
}
const getUserName = ()=>{
  let user = localStorage.getItem(RK_ADMIN)
  let userO = JSON.parse(user)
  return userO?userO.name:null
}
const getHeader = ()=>{
    return {
        headers:{
            'content-type': 'application/json',
            token: getToken()
        }
    }
}
const getUserEmail = ()=>{
  let user = localStorage.getItem(RK_ADMIN)
  let userO = JSON.parse(user)
  return userO?userO.email:null
}
const getToken = () =>{
   return localStorage.getItem(RK_TOKEN)
}
const deleteToken = ()=>{
   localStorage.removeItem(RK_TOKEN)
}
const isLoggedIn = ()=>{
   return localStorage.getItem(RK_TOKEN)!== null
}
const getDateFormat = (d)=>{
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    let y = d.getFullYear()
    let dd = '0'+d.getDate()
    let h = '0'+d.getHours()
    let min = '0'+d.getMinutes()
    return dd.slice(-2)+' '+months[d.getMonth()]+' '+y+', '+h.slice(-2)+':'+min.slice(-2)
 }
export {
    getDateFormat,
    removeDuplicatesStringArray, 
    getHeader, capitalizeFirstLetter, saveToken, getToken, deleteToken, isLoggedIn, saveUser, getUserName, getUserEmail}