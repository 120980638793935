
import React, {  useEffect, useState, useCallback  } from 'react'
import ReactLoading from "react-loading";
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import DoctorCard from '../components/Doctor/DoctorCard'

import Paginations from '../components/Pagination/Paginations'
import data from '../components/data'
import '../components/Pagination/style.css'

import { doctorList } from '../redux/actions/doctorActions';

export default function Doctors() {
    const [done, setDone] = useState(undefined);
    const dispatch = useDispatch()
    const {loading, error, doctorList : doctors} = useSelector(state => state.listDoctor)
  
    useEffect(() => {
      dispatch(doctorList())
    }, [])

    const data = []
    data.push(doctors);

  //   pagination
  const [currentPage, setCurrentPage] = useState(1);
  let NUM_OF_RECORDS = data.length;
  let LIMIT = 8;
  const onPageChanged = useCallback(
    (event, page) => {
      event.preventDefault();
      setCurrentPage(page);
    },
    [setCurrentPage]
  );
  const currentData = data.slice(
    (currentPage - 1) * LIMIT,
    (currentPage - 1) * LIMIT + LIMIT
  );
  useEffect(() => {
    setTimeout(() => {
      setDone(true);
     
    }, 2000);
  }, []);

  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
                      <h4 className="fw-bold mt-3">DOCTOR'S</h4>
                          <div className="row justify-content-center">
                         
                          {!done ? (
                                <div className="col-md-12 my-3 text-center">
                                  {/* <ReactLoading type={'bubbles'} color={'#0098D9'} height={'20%'} width={'20%'} /> */}
                                </div>
                            ) : (
                              doctors.map((item) => {
                                  return (
                                      <DoctorCard id={item.id} data={item} />
                                  );
                               })
                            )}
                          {!done ? (
                              <div className="col-md-12 my-3 text-center">
                             </div>
                            ) : (
                              <div className="pagination-wrapper mb-5">
                                <Paginations totalRecords={NUM_OF_RECORDS} pageLimit={LIMIT} pageNeighbours={2} onPageChanged={onPageChanged} currentPage={currentPage} />
                              </div>
                              )}
                          </div>
            </div>
          </div>
        </div>
    </>
  )
}