import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from '@mui/material';
const BackBtn = (props) => {
    
    return (
       <Button onClick={props.onClick} size='small' variant='outlined' startIcon={<ArrowBackIosIcon />}>Back</Button>
    );
};

export default BackBtn;