import React, {useMemo, useEffect, useState} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import MaterialReactTable from 'material-react-table';
import { deleteMedicine, medicineList } from '../redux/actions/medicineActions';
import PreLoader from '../components/PreLoader';
import { MEDICINE_DELETE_RESET } from '../redux/constants/medicineConstants';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import YesNoDialog from '../rd-pages/YesNoDialog';
import { IconButton } from '@mui/material';
import axios from 'axios';
import { API_ENDPOINT } from '../rd-utils/constants';
import CustomCircularP from '../rd-pages/widgets/customCircularP';
import { toast } from 'react-toastify';
import { getHeader } from '../rd-utils/util';
const MedicineList = () => {
  const [dopen, setDopen]= useState(false)
  const [cid, setCid] = useState("")
  const onYesResponse = ()=>{
    setDopen(false)
    //dispatch(deleteMedicine(cid))
    deleteDisease(cid)
  }
  const onNoResponse = ()=>{
    setDopen(false)
  }
  function deleteDisease(id){
    setLoading(true)
    axios.post(API_ENDPOINT+'admin/delete-medicine', {id}, getHeader()).then(res=>{
       setLoading(false)
       let d = res.data
       if(d.success){
         window.location.reload()
       }else{
         toast(d.message)
       }
    })
 }
  const confirmDelete = (id)=>{
     setDopen(true)
     setCid(id)
  }

  const navigate = useNavigate()
  const [medicines, setMedicines] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadMedicines()
  }, [])
  function loadMedicines(){
    setLoading(true)
    axios.post(API_ENDPOINT+'admin/get-products', {type: 'medicine'}, getHeader()).then(res=>{
      setLoading(false)
      let d = res.data
      if(d.success){
        setMedicines(d.message)
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', 
        header: 'Medicine Name',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'manufacturer', //simple recommended way to define a column
        header: 'Manufacturer',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'diseases', //simple recommended way to define a column
        header: 'Disease',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'category', //simple recommended way to define a column
        header: 'Category',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'edit', 
        header: 'Edit',
        Cell: ({ cell, row }) => (
          <IconButton onClick={() => (cell.row && cell.row.original) && navigate(`/medicine/edit/${cell.row.original._id}`)} aria-label="delete">
            <EditIcon />
          </IconButton>
          ),
      },
      {
        accessorKey: 'delete', 
        header: 'Delete',
        Cell: ({ cell, row }) => (
          <IconButton onClick={() => (cell.row && cell.row.original) && confirmDelete(cell.row.original._id)} aria-label="delete">
            <DeleteIcon />
          </IconButton>
          ),
      },
      
    ],
    [],
  );



  return (
    <div className='container my-3'>
      <CustomCircularP show={loading}/>
       <YesNoDialog dopen={dopen} setDopen={setDopen} onYesResponse={onYesResponse} onNoResponse={onNoResponse} message="Are you sure you want to delete this?" />
      <div className='d-flex justify-content-between align-item-center p-3'>
        <h1 className='heading'>Medicine List</h1>
        <button type='button' className='btn btn-primary' onClick={()=>navigate('/medicine/add')}>Add Medicine</button>
      </div>
    
     {<MaterialReactTable 
      columns={columns} 
      data={medicines} 
      enableColumnOrdering //enable some features
      enableRowSelection 
      enableStickyHeader
      // onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
      // state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
      />}
    </div>
  )
}

export default MedicineList