import axios from "axios";

import {
    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAIL,
    CUSTOMER_ORDER_LIST_REQUEST,
    CUSTOMER_ORDER_LIST_SUCCESS,
    CUSTOMER_ORDER_LIST_FAIL,
    CUSTOMER_ORDER_DETAILS_REQUEST,
    CUSTOMER_ORDER_DETAILS_SUCCESS,
    CUSTOMER_ORDER_DETAILS_FAIL,
    CUSTOMER_ORDER_LIST_BY_ID_REQUEST,
    CUSTOMER_ORDER_LIST_BY_ID_SUCCESS,
    CUSTOMER_ORDER_LIST_BY_ID_FAIL,
} from '../constants/orderConstants'
import { logout } from "./userActions";

export const orderList = () => async(dispatch, getState) => {
    
    try {
        dispatch({
          type: ORDER_LIST_REQUEST
      })

        const {
            userSignIn: { userInfo },
          } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth' : `${userInfo.token}`
            },
          }

        const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}api/order/admin/order/list`, config)
        
          dispatch({
            type: ORDER_LIST_SUCCESS,
            payload: data
        })
         
        
    } catch (error) {
      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }

        dispatch({
            type: ORDER_LIST_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}

export const orderListOfCustomer = () => async(dispatch, getState) => {
    
  try {
      dispatch({
        type: CUSTOMER_ORDER_LIST_REQUEST
    })

      const {
          userSignIn: { userInfo },
        } = getState()

      const config = {
          headers: {
            'Content-Type': 'application/json',
            'auth' : `${userInfo.token}`
          },
        }

      const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}api/order/my/list`, config)
      
        dispatch({
          type: CUSTOMER_ORDER_LIST_SUCCESS,
          payload: data
      })
       
      
  } catch (error) {
    if((error.response && error.response.data) && error.response.data.status === 401){
      dispatch(logout())
    }

      dispatch({
          type: CUSTOMER_ORDER_LIST_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      
  }
}

export const orderListOfCustomerById = () => async(dispatch, getState) => {
    
  try {
      dispatch({
        type: CUSTOMER_ORDER_LIST_BY_ID_REQUEST
    })

      const {
          userSignIn: { userInfo },
        } = getState()

      const config = {
          headers: {
            'Content-Type': 'application/json',
            'auth' : `${userInfo.token}`
          },
        }

      const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}api/order/my/:id`, config)
      
        dispatch({
          type: CUSTOMER_ORDER_LIST_BY_ID_SUCCESS,
          payload: data
      })
       
      
  } catch (error) {
    if((error.response && error.response.data) && error.response.data.status === 401){
      dispatch(logout())
    }

      dispatch({
          type: CUSTOMER_ORDER_LIST_BY_ID_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      
  }
}

export const orderDetailsById = (id) => async(dispatch, getState) => {
    
  try {
      dispatch({
        type: CUSTOMER_ORDER_DETAILS_REQUEST
    })

      const {
          userSignIn: { userInfo },
        } = getState()

      const config = {
          headers: {
            'Content-Type': 'application/json',
            'auth' : `${userInfo.token}`
          },
        }

      const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}api/order/${id}`, config)
      
        dispatch({
          type: CUSTOMER_ORDER_DETAILS_SUCCESS,
          payload: data
      })
       
      
  } catch (error) {
    if((error.response && error.response.data) && error.response.data.status === 401){
      dispatch(logout())
    }

      dispatch({
          type: CUSTOMER_ORDER_DETAILS_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      
  }
}



