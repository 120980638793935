import React, {useMemo, useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import MaterialReactTable from 'material-react-table';
import { companyList, deleteCompany } from '../redux/actions/companyActions';
import PreLoader from '../components/PreLoader';
import { COMPANY_DELETE_RESET } from '../redux/constants/companyConstants';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import YesNoDialog from '../rd-pages/YesNoDialog';
import axios from 'axios';
import { API_ENDPOINT } from '../rd-utils/constants';
import CustomCircularP from '../rd-pages/widgets/customCircularP';
import { toast } from 'react-toastify';
import { getHeader } from '../rd-utils/util';
const CompanyList = () => {
  const [dopen, setDopen]= useState(false)
  const [cid, setCid] = useState("")
  const navigate = useNavigate()
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(false)
  const onYesResponse = ()=>{
    setDopen(false)
    //dispatch(deleteCompany(cid))
    deleteCompany(cid)
  }
  const onNoResponse = ()=>{
    setDopen(false)
  }
  const confirmDelete = (id)=>{
     setDopen(true)
     setCid(id)
  }
  useEffect(()=>{
    loadCompanies()
  }, [])
  function loadCompanies(){
    setLoading(true)
    axios.get(API_ENDPOINT+'admin/get-companies', getHeader()).then(res=>{
      setLoading(false)
      let d = res.data
      if(d.success){
        setCompanies(d.message)
      }
    })
  }
  function deleteCompany(id){
     setLoading(true)
     axios.post(API_ENDPOINT+'admin/delete-company', {id}, getHeader()).then(res=>{
        setLoading(false)
        let d = res.data
        if(d.success){
          window.location.reload()
        }else{
          toast(d.message)
        }
     })
  }


  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', 
        header: 'Company Name',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'contactPerson', //simple recommended way to define a column
        header: 'Person Name',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'mailAddress', //simple recommended way to define a column
        header: 'Email Id',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'phoneNumber', //simple recommended way to define a column
        header: 'Phone Number',
        // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorKey: 'edit', 
        header: 'Edit',
        Cell: ({ cell, row }) => (
          <IconButton onClick={() => (cell.row && cell.row.original) && navigate(`/company/edit/${cell.row.original._id}`)} aria-label="delete">
          <EditIcon />
        </IconButton>
          ),
      },
      {
        accessorKey: 'delete', 
        header: 'Delete',
        Cell: ({ cell, row }) => (
          <IconButton onClick={() => (cell.row && cell.row.original) && confirmDelete(cell.row.original._id)} aria-label="delete">
            <DeleteIcon />
          </IconButton>
          ),
      },
      
    ],
    [],
  );
  return (
    <div className='container my-3'>
        <CustomCircularP show={loading}/>
        <YesNoDialog dopen={dopen} setDopen={setDopen} onYesResponse={onYesResponse} onNoResponse={onNoResponse} message="Are you sure you want to delete this?" />
      <div className='d-flex justify-content-between align-item-center p-3'>
      <h1 className='heading'>Company List</h1>
      <button type='button' className='btn btn-primary' onClick={()=>navigate('/company/add')}>Add Company</button>
      </div>
    
     {<MaterialReactTable 
      columns={columns} 
      data={companies} 
      enableColumnOrdering //enable some features
      enableRowSelection 
      enableStickyHeader
      // onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
      // state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
      />}
    </div>
  )
}

export default CompanyList