import React from 'react';

const SideItem = (props) => {
    const {icon, label, isActive= false, link} = props
    return (
        <a href={link}> <div className={isActive?'side-item-c active': 'side-item-c'}>
           {icon}
            <span style={{marginLeft: '16px'}}>{label}</span>
            </div>
         </a>
    );
};

export default SideItem;