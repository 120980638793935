import React,  {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import { uploadPurchaseRecieptCompany, companyDetails, updateCompany } from '../redux/actions/companyActions'
import {Link, useNavigate, useParams} from 'react-router-dom'
import { COMPANY_UPDATE_RESET, COMPANY_UPLOAD_RESET } from '../redux/constants/companyConstants'
import CustomCircularP from '../rd-pages/widgets/customCircularP'
import axios from 'axios'
import { API_ENDPOINT } from '../rd-utils/constants'
import { toast } from 'react-toastify'
import { getHeader } from '../rd-utils/util'

const EditDisease = () => {
    const loc = document.location.href.replace(/\/+$/, "")
    const keysUrl = loc.split('/')
    const linkCode = keysUrl[5]
    const navigate = useNavigate()
    const [disease, setDisease] = useState('')
    const [loading, setLoading] = useState(false)
    const updateDisease = (e)=>{
        e.preventDefault()
        let toUpdateO={
            id: linkCode,
            name: disease,
        }
        axios.post(API_ENDPOINT+'admin/update-disease', toUpdateO, getHeader()).then(res=>{
            let d = res.data
            if(d.success){
                navigate('/diseases')
            }else{
                toast(d.message)
            }
        })
    }
    useEffect(()=>{
        loadDisease()
    }, [])
    function loadDisease(){
        setLoading(true)
        axios.get(`${API_ENDPOINT}admin/disease/${linkCode}`, getHeader()).then(res=>{
            setLoading(false)
            let d = res.data
            if(d.success){
                let dt = d.message
                setDisease(dt.name)
                //setUserData(d.message)
            }else{
                toast(d.message)
            }
        })
    }
  return (
    <div>
        <CustomCircularP show={loading}/>
        <div className='container p-5'>
            <h1>Disease Information</h1>
            <form className='row mt-3' onSubmit={(e) =>updateDisease(e)}>
                <div className='col-6 mb-3'>
                <label htmlFor="companyName" className="form-label"> Disease Name</label>
                <input className="form-control" type="text" placeholder='Disease Name'  value={disease} onChange={(e)=>setDisease(e.target.value)} required/>
                </div>
                <div>
                    <button type='submit' className='btn btn-primary mt-3'> Update Disease</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default EditDisease