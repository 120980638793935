import React, {useMemo, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import MaterialReactTable from 'material-react-table';
import PreLoader from '../components/PreLoader';
import { USER_DELETE_RESET } from '../redux/constants/userConstants';
import YesNoDialog from '../rd-pages/YesNoDialog';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import CustomCircularP from '../rd-pages/widgets/customCircularP'
import axios from 'axios';
import { API_ENDPOINT } from '../rd-utils/constants';
import { toast } from 'react-toastify';
import { getHeader } from '../rd-utils/util';
const UserList = () => {
  const navigate = useNavigate()
  const [dopen, setDopen]= useState(false)
  const [cid, setCid] = useState("")
  const [loading, setLoading] = useState(false)
  const [userList, setUserList] = useState([])

  const onYesResponse = ()=>{
    setDopen(false)
    deactivateUser(cid)
  }
  const onNoResponse = ()=>{
    setDopen(false)
  }
  const confirmDelete = (id)=>{
     setDopen(true)
     setCid(id)
  }
  useEffect(()=>{
    loadUsers()
  }, [])
function loadUsers(){
  setLoading(true)
  axios.get(API_ENDPOINT+'admin/get-users', getHeader()).then(res=>{
    setLoading(false)
    let d = res.data
    setUserList(d.message)
  })
}
function deactivateUser(id){
  setLoading(true)
  axios.post(API_ENDPOINT+'admin/de-activate-user', {id: id}, getHeader()).then(res=>{
    setLoading(false)
    let d = res.data
    if(d.success){
      toast(d.message)
    }else{
      toast(d.message)
    }
    window.location.reload()
  })
}

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', 
        header: 'Name',
      },
      {
        accessorKey: 'email', 
        header: 'Email Id',
      },
      {
        accessorKey: 'phoneNumber', 
        header: 'Phone Number',
      },
      {
        accessorKey: 'view', 
        header: 'View',
        Cell: ({ cell, row }) => (
          <button className='btn btn-primary' onClick={() => (cell.row && cell.row.original) && navigate(`/user/view/${cell.row.original._id}`)}>View</button>
        ),
      },
    ],
    [],
  );

  return (
    <div className='container my-3'>
      <CustomCircularP show={loading}/>
         <YesNoDialog dopen={dopen} setDopen={setDopen} onYesResponse={onYesResponse} onNoResponse={onNoResponse} message="Are you sure you want to remove this user?" />
      <div className='p-3'>
        <h1 className='heading'>Users List</h1>
      </div>
    {
      <MaterialReactTable 
      columns={columns} 
      data={userList} 
      enableColumnOrdering 
      enableRowSelection 
      enableStickyHeader
      // onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
      // state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
      />}
    </div>
  )
}

export default UserList