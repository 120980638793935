import { Button, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { LoadingButton } from '@mui/lab';
import { maxWidth } from '@mui/system';
import { toast } from 'react-toastify';
import YesNoDialog from '../rd-pages/YesNoDialog';
import CustomCircularP from '../rd-pages/widgets/customCircularP';
import { API_ENDPOINT } from '../rd-utils/constants';
import { getHeader } from '../rd-utils/util';

export default function OrderReceipt(props) {
    const {order, back} = props
    const [loading, setLoading] = useState(false)

    const [dopen, setDopen]= useState(false)
    const onYesResponse = ()=>{
      setDopen(false)
      approveOrder()
     
    }
    const onNoResponse = ()=>{
       setDopen(false)
    }

    const approveOrder = ()=>{
        setLoading(true)
        axios.post(API_ENDPOINT+'admin/approve-order', {order_id: order.order_id}, getHeader()).then(res=>{
            let d = res.data
            setLoading(false)
            if(d.success){
                document.location.reload()
            }else{
                toast(d.message)
            }
        })
    }
    return(
        <div>
            <CustomCircularP show={loading}/>
              <YesNoDialog dopen={dopen} setDopen={setDopen} onYesResponse={onYesResponse} onNoResponse={onNoResponse} message="Are you sure you want to approve this Order?" />
             <Button onClick={back} variant='outlined' startIcon={<ArrowBackIosIcon />}>Back</Button>
             <div style={{marginTop: '12px'}}>Order ID: <span style={{color: 'blue'}}>#{order.order_id}</span></div>

             <div style={{padding: '12px', maxWidth: '400px', border: '1px solid #ddd'}}>
                <img src= {order.receipt.url} style={{height: '192px', margin: '4px', borderRadius: '4px', display: 'block', boxShadow: 'box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)'}} alt="pl"/>
                <TextField disabled value={order.receipt.transaction_id} sx={{marginTop: '16px'}} variant="standard" fullWidth size='small' label='Transaction ID' />

                <LoadingButton disabled={order.status === 'approved'} fullWidth sx={{marginTop: '20px'}} loading={loading} loadingPosition='start' startIcon={<ReceiptIcon />} onClick={()=>setDopen(true)} variant='contained'>Approve Order</LoadingButton>
             </div>
        </div>
    )
}