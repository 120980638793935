import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

import {
    USER_SIGN_UP_REQUEST,
    USER_SIGN_UP_SUCCESS,
    USER_SIGN_UP_FAIL,
    USER_SIGN_IN_REQUEST,
    USER_SIGN_IN_SUCCESS,
    USER_SIGN_IN_FAIL,
    USER_LOGOUT_REQUEST,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_ADD_REQUEST,
    USER_ADD_SUCCESS,
    USER_ADD_FAIL
} from '../constants/userConstants'

export const userRegister = (userData) => async(dispatch) => {
    dispatch({
        type: USER_SIGN_UP_REQUEST
    })
    try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }
        const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}api/user/signup`, userData, config)
        console.log("userData",data)

        dispatch({
            type: USER_SIGN_UP_SUCCESS,
            payload: data
        })

        if(data.status === 409){
            toast.warn(data.message, {
              position: toast.POSITION.BOTTOM_LEFT
            });
        }else if(data.status === 201){
            toast.success("User Successfully Registered", {
              position: toast.POSITION.BOTTOM_LEFT
            });
        } 
    } catch (error) {
        dispatch({
            type: USER_SIGN_UP_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
    }
}

export const userLogin = (userData) => async(dispatch) => {
    
    try {

      dispatch({
        type: USER_SIGN_IN_REQUEST
    })
        const config = {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}api/user/signin`, userData, config)
        dispatch({
            type: USER_SIGN_IN_SUCCESS,
            payload: data
        })     
        if(data.status === 404){
          toast("Email Verification is Pending")
        }   
        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: USER_SIGN_IN_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}

export const fetchUsers = () => async(dispatch, getState) => {
  
    dispatch({
        type: USER_LIST_REQUEST
    })
    try {

       const {
      userSignIn: { userInfo },
    } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth': `${userInfo.token}`
            },
          }

        axios.get(`${process.env.REACT_APP_BASE_URL}api/user/list`, config)
        .then((res)=>{
          dispatch({
            type: USER_LIST_SUCCESS,
            payload: res.data
        }) })

      
        
    } catch (error) {
      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }

        dispatch({
            type: USER_LIST_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}

export const fetchUserDetails = (id) => async(dispatch, getState) => {
    dispatch({
        type: USER_DETAILS_REQUEST
    })
    try {

      const {
        userSignIn: { userInfo },
      } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth': userInfo.token
            },
          }

        const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}api/user/${id}`, config)

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        })
        
    } catch (error) {
      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }

        dispatch({
            type: USER_DETAILS_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}

export const updateUserDetails = (userData) => async(dispatch, getState) => {
    dispatch({
        type: USER_UPDATE_REQUEST
    })
    try {

      const {
        userSignIn: { userInfo },
      } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth': userInfo.token
            },
          }

        const {data} = axios.put(`${process.env.REACT_APP_BASE_URL}api/user/${userData._id}`, userData, config)

        dispatch({
            type: USER_UPDATE_SUCCESS,
            payload: data
        })
        
    } catch (error) {

      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }

        dispatch({
            type: USER_UPDATE_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}


export const deleteUser = (id) => async(dispatch, getState) => {
    dispatch({
        type: USER_DELETE_REQUEST
    })
    try {

      const {
        userSignIn: { userInfo },
      } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth': userInfo.token
            },
          }

        const {data} = axios.delete(`${process.env.REACT_APP_BASE_URL}api/user/${id}`, config)
       
        dispatch({
            type: USER_DELETE_SUCCESS,
            payload: data
        })
        
    } catch (error) {
      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }
        dispatch({
            type: USER_DELETE_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
    }
}

export const logout = () => async(dispatch) => {
  localStorage.removeItem('userInfo')
  dispatch({ type: USER_LOGOUT_REQUEST })
}

export const addMember = (userData) => async(dispatch, getState) => {
  dispatch({
      type: USER_ADD_REQUEST
  })
  try {

    const {
      userSignIn: { userInfo },
    } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'auth': userInfo.token
        },
      }

      const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}api/user/signup`, userData, config)

      dispatch({
          type: USER_ADD_SUCCESS,
          payload: data
      })
      
  } catch (error) {
    if((error.response && error.response.data) && error.response.data.status === 401){
      dispatch(logout())
    }

      dispatch({
          type: USER_ADD_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      
  }
}





