import React, { useEffect, useState, useCallback } from 'react'
import { Link  } from 'react-router-dom'

import BlogCard from '../components/Blog/BlogCard'
import Paginations from '../components/Pagination/Paginations'
import data from '../components/data'
import '../components/Pagination/style.css'


  
export default function Blog() {

    //   pagination
    const [currentPage, setCurrentPage] = useState(1);
    let NUM_OF_RECORDS = data.length;
    let LIMIT = 6;
  
    const onPageChanged = useCallback(
      (event, page) => {
        event.preventDefault();
        setCurrentPage(page);
      },
      [setCurrentPage]
    );
    const currentData = data.slice(
      (currentPage - 1) * LIMIT,
      (currentPage - 1) * LIMIT + LIMIT
    );

  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
                <div className='my-3'>
                    <p><Link to={"/"} className="custom-text-color">Home</Link> <span> {'>'} </span> <span className='text-muted'> Mind & Body </span></p>
                </div>
                <h3 className='fw-bold'>Mind & Body</h3>
                <div className="row">
                    <div className="col-md-9">
                        <div className='row'>
                        <div className="d-flex d-filter">
                                <button className='btn btn-outline-dark me-2 btn-sm d-filter'>All</button>
                                <button className='btn btn-outline-dark me-2 btn-sm d-filter'>Ailments</button>
                                <button className='btn btn-outline-dark me-2 btn-sm d-filter'>Beauty & Fitness</button>
                                <button className='btn btn-outline-dark me-2 btn-sm d-filter'>Food</button>
                                <button className='btn btn-outline-dark me-2 btn-sm d-filter'>Wellness</button>
                                <button className='btn btn-outline-dark me-2 btn-sm d-filter'>Videos</button>
                                <button className='btn btn-outline-dark me-2 btn-sm d-filter'>In The News</button>
                                <button className='btn btn-outline-dark me-2 btn-sm d-filter'>Pet Care</button>
                                <button className='btn btn-outline-dark me-2 btn-sm d-filter'>In The News</button>
                        </div>
                      
                        { currentData.map((item) => {
                            return (
                                 <BlogCard id={item.id} name={item.title} />
                        );
                        })}

                            <div className="pagination-wrapper mb-5">
                                <Paginations
                                    totalRecords={NUM_OF_RECORDS}
                                    pageLimit={LIMIT}
                                    pageNeighbours={2}
                                    onPageChanged={onPageChanged}
                                    currentPage={currentPage}
                                />
                                </div>  
                     </div>
                        
                    </div>
                    <div className="col-md-3 d-filter">
                        <form className="d-flex my-3" role="search">
                            <input className="form-control me-2 input-sm" type="search" placeholder="Search post here..." aria-label="Search"/>
                        </form>
                        <div className="card my-3">
                            <div className="card-body">
                                <h6 className='text-muted'>CATEGORIES</h6>
                                   <p><small>Ayurvedic & Home Remedies (658)</small></p> 
                                   <p><small>Disease & Disorder (1086)</small></p>
                                   <p><small>Eye care (15)</small></p>
                                   <p><small>Food & Nutrition (900)</small></p>
                                   <p><small>Hair care (48)</small></p>
                                   <p><small>Health Day (223)</small></p>
                                   <p><small>Health News (625)</small></p>
                                   <p><small>Mental Health (15)</small></p>
                                   <p><small>Mind & Body (23)</small></p>
                                   <p><small>Personal Care (508)</small></p>
                                    <hr />
                                <h6 className='text-muted'>ARCHIVES</h6>
                                <p><small>2022</small></p>
                                <p><small>2021</small></p>
                                <p><small>2020</small></p>
                                <p><small>2019</small></p>
                                <p><small>2018</small></p>
                                <p><small>2017</small></p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
          </div>
        </div>
    </>
  )
}