import React, {useMemo, useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import MaterialReactTable from 'material-react-table';
import { deleteDoctor, doctorList } from '../redux/actions/doctorActions';
import doctorProfileImg from '../assets/images/doctor-profile-img.jpg'
import PreLoader from '../components/PreLoader';
import { DOCTOR_DELETE_RESET } from '../redux/constants/doctorConstants';
import CustomCircularP from '../rd-pages/widgets/customCircularP';
import axios from 'axios';
import { API_ENDPOINT } from '../rd-utils/constants';
import { getHeader } from '../rd-utils/util';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import YesNoDialog from '../rd-pages/YesNoDialog';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';

const DoctorList = () => {
  const [dopen, setDopen]= useState(false)
  const [cid, setCid] = useState("")
  const onYesResponse = ()=>{
    setDopen(false)
    //dispatch(deleteMedicine(cid))
    deleteDisease(cid)
  }
  const onNoResponse = ()=>{
    setDopen(false)
  }
  function deleteDisease(id){
    setLoading(true)
    axios.post(API_ENDPOINT+'admin/delete-doctor', {id}, getHeader()).then(res=>{
       setLoading(false)
       let d = res.data
       if(d.success){
         window.location.reload()
       }else{
         toast(d.message)
       }
    })
 }
  const confirmDelete = (id)=>{
     setDopen(true)
     setCid(id)
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [doctors, setDoctors] = useState([])
  const [loading, setLoading] = useState(false)


  useEffect(()=>{
    loadDoctors()
  }, [])
  function loadDoctors(){
    setLoading(true)
    axios.get(API_ENDPOINT+'admin/get-doctors', getHeader()).then(res=>{
      let d = res.data
      setLoading(false)
      if(d.success){
          setDoctors(d.message)
      }
    })

  }
 


  const columns = useMemo(
    () => [
      {
        accessorKey: 'photo', 
        header: 'Profile Image',
        Cell: ({cell}) => {
          return <img src={cell.row.original.pic} alt="" style={{height: '90px', borderRadius: '8px'}}/>
        }
      },
      {
        accessorKey: 'name', 
        header: 'Name',
      },
      {
        accessorKey: 'designation', 
        header: 'Designation',
      },
      {
        accessorKey: 'phone', 
        header: 'Phone Number',
      },
      {
        accessorKey: 'edit', 
        header: 'Edit',
        Cell : (({cell, row})=>{
          return  <IconButton onClick={() => (cell.row && cell.row.original) && navigate(`/doctor/edit/${cell.row.original._id}`)} aria-label="delete">
          <EditIcon />
        </IconButton>
      })
      },
      {
        accessorKey: 'delete', 
        header: 'Delete',
        Cell: ({ cell, row }) => (
          <IconButton onClick={() => (cell.row && cell.row.original) && confirmDelete(cell.row.original._id)} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        ),
      },
      
    ],
    [],
  );



  return (
    <div className='container my-5'>
      <CustomCircularP show={loading}/>
      <YesNoDialog dopen={dopen} setDopen={setDopen} onYesResponse={onYesResponse} onNoResponse={onNoResponse} message="Are you sure you want to delete this?" />
      <div className='d-flex justify-content-between align-item-center p-3'>
      <h1 className='heading'>Doctors List</h1>
      <button type='button' className='btn btn-primary' onClick={()=>navigate('/doctor/add')}>Add Doctor</button>
      </div>
      <MaterialReactTable 
      columns={columns} 
      data={doctors} 
      enableColumnOrdering 
      enableRowSelection 
      enableStickyHeader
      // onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
      // state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
      />
    </div>
  )
}

export default DoctorList