import React,  {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import img from '../assets/images/doctor-profile-img.jpg'
import { addMember } from '../redux/actions/doctorActions'
import { userRegister } from '../redux/actions/userActions'

const AddMember = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [name, setName] = useState('')
    const [role, setRole] = useState('')
    const [emailId, setEmailId] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')

    const userSignUp = useSelector(state => state.userSignUp)
    
    const {loading, error, userInfo} = userSignUp

    useEffect(()=>{
        if(userInfo && !userInfo.userInfo.isAdmin){
            navigate('/login')
          }
    }, [])

    const addMemberHandler = (e) => {
        e.preventDefault()
        dispatch(userRegister({
            name,
            role,
            email:emailId,
            phoneNumber,
            password
        }))
      }
      
      useEffect(()=>{
        if(userInfo){
          navigate('/admin/member')
        }
    
      }, [userInfo])

  return (
    <div>
        <div className='container p-5'>
            <h1>Add Member</h1>
            <form className='row mt-3' onSubmit={(e) =>addMemberHandler(e)}>

                <div className='col-6 mb-3'>
                <label htmlFor="name" className="form-label"> Name*</label>
                <input className="form-control" type="text" placeholder='Name' id='name' onChange={(e)=>setName(e.target.value)} required/>
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="role"> Role*</label>
                <select className="form-select" id='role' defaultValue={role} onChange={(e) => setRole(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="BackendTeam">Backend Team</option>
                  <option value="SalesManager">Sales Manager</option>
                  <option value="AccountManager">Account Manager</option>
                </select>
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="emailId"> Email Id*</label>
                <input className="form-control" type="email" placeholder='Email Id' id='emailId' onChange={(e)=>setEmailId(e.target.value) } required/>
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="phone"> Phone Number*</label>
                <input className="form-control" type="tel" maxLength={'10'} minLength={10} placeholder='Phone Number' id='phone' onChange={(e)=>{
                    if(e.target.value && e.target.value.match("[0-9]+")){
                        setPhoneNumber(e.target.value.match("[0-9]+")[0])
                      }else if(!e.target.value){
                        setPhoneNumber(e.target.value)
                      }
                }} required/>
                </div>

                <div className='col-6 mb-3'>
                <label className="form-label" htmlFor="password"> Password*</label>
                <input className="form-control" type="password" placeholder='Password' id='password' onChange={(e)=>setPassword(e.target.value) } required/>
                </div>        

                <div>
                    <button type='submit' className='btn btn-primary mt-3'> Add Member</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default AddMember